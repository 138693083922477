import { Col, Descriptions, Row, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { Project, RelationInstance, relationInstances } from '../api';
import './NetworkTree.css';
import KBProgressChart from './KBProgressChart';
function GeneralInformation(props: {
    relationInstancesMap: {
        [k: string]: Array<RelationInstance>;
    };
    project: Project;
    loading: boolean;
}) {
    const entityTypes = Object.keys(props.relationInstancesMap)
        .map(relId => [relId.split('-')[0], relId.split('-')[2]])
        .flatMap(arr => arr)
        .filter(ri => !ri.startsWith('NEG_'))
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a, b) => a.localeCompare(b));

    const allRelInsts: Array<RelationInstance> = Object.values(props.relationInstancesMap)
        .flatMap(arr => arr)
        .filter(ri => !ri.relationLabel.startsWith('NEG_'));

    const namesByTypeVal: { [entityType: string]: Set<string> } = allRelInsts
        .flatMap(ri => [
            [ri.entity1Type, ri.entity1Name],
            [ri.entity2Type, ri.entity2Name]
        ])
        .reduce((accumulator, item) => {
            if (!(item[0] in accumulator)) {
                accumulator[item[0]] = new Set<string>();
            }
            accumulator[item[0]].add(item[1]);
            return accumulator;
        }, {});

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Card bordered={true} title="General Information" loading={props.loading}>
                        <Descriptions bordered size="small" column={1}>
                            <Descriptions.Item label="System Name">
                                {props.project.name}
                            </Descriptions.Item>
                            <Descriptions.Item label="Display Name">
                                {props.project.displayName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Description">
                                {props.project.description}
                            </Descriptions.Item>
                            <Descriptions.Item label="# Entities">
                                {Object.values(namesByTypeVal).length === 0
                                    ? 0
                                    : Object.values(namesByTypeVal)
                                          .map(names => names.size)
                                          .reduce((a, b) => a + b, 0)}
                            </Descriptions.Item>
                            <Descriptions.Item label="# Relations">
                                {allRelInsts.length}
                            </Descriptions.Item>
                            <Descriptions.Item label="Entities (counts)">
                                {entityTypes.length === 0 || allRelInsts.length === 0
                                    ? 'NA'
                                    : Object.keys(namesByTypeVal)
                                          .sort((a, b) => a.localeCompare(b))
                                          .map<React.ReactNode>(entityType => (
                                              <span>
                                                  {entityType} ({namesByTypeVal[entityType].size})
                                              </span>
                                          ))
                                          .reduce((prev, curr) => [prev, ', ', curr])}
                            </Descriptions.Item>
                            <Descriptions.Item label="Relations (counts)">
                                {Object.keys(props.relationInstancesMap).length === 0
                                    ? 'NA'
                                    : Object.keys(props.relationInstancesMap)
                                          .filter(relId => !relId.startsWith('NEG_'))
                                          .map<React.ReactNode>(k => (
                                              <span>
                                                  {k} ({props.relationInstancesMap[k].length})
                                              </span>
                                          ))
                                          .reduce((prev, curr) => [prev, <br />, curr])}
                            </Descriptions.Item>
                            <Descriptions.Item label="Contacts">
                                {props.project.contacts.join(', ')}
                            </Descriptions.Item>
                            <Descriptions.Item label="Visibility">
                                {props.project.isPublic ? 'Public' : 'Private'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Download">
                                <a href={`/api/projects/${props.project.name}/owl`}>OWL</a>
                                {', '}
                                <a href={`/api/projects/${props.project.name}/csv`}>CSV</a>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default GeneralInformation;
