export const noWhitespaceValidator = async (_, value) => {
    if (/\s/g.test(value)) {
        return Promise.reject(new Error('The field value cannot contain whitespace.'));
    }
};

export const systemNameLengthValidator = async (_, value) => {
    if (value.length <= 2 || !/^[A-Za-z]*$/.test(value)) {
        return Promise.reject(
            new Error(
                'The field value must be 3 charters or more. Only English characters are allowed (no whitespace, digits or symbols).'
            )
        );
    }
};

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);

export const pairwise = (arr, func) => {
    for (let i = 0; i < arr.length - 1; i++) {
        func(arr[i], arr[i + 1]);
    }
};

// https://stackoverflow.com/questions/37318808/what-is-the-in-place-alternative-to-array-prototype-filter
export function filterInPlace(a, condition) {
    let i = 0;
    let j = 0;

    while (i < a.length) {
        const val = a[i];
        if (condition(val, i, a)) a[j++] = val;
        i++;
    }

    a.length = j;
    return a;
}

function arrayEquals(a, b) {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    );
}
