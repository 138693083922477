import React, { useState } from 'react';
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd';
import { CaretRightOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import { db } from '../bl';
import { RelationTableData } from './RelationTable';
import { SortOrder } from 'antd/es/table/interface';

interface Props {
    queriesData: Array<QueryTableData>;
    setQueriesData: (queriesData: Array<QueryTableData>) => void;
    setSelectedRows: (selectedRows: Array<QueryTableData>) => void;
    loading: boolean;
}

export type QueryTypeVals = 'boolean' | 'token' | 'syntactic';
export interface QueryProps {
    qtype: QueryTypeVals;
    query: string;
    filters: string;
}

export interface QueryTableData {
    key: number;
    queryId: string;
    queries: Map<string, QueryProps>;
    caseStrategy: string;
    originalURL: string; // the SPIKE URL for the query
    originalQueryParams: string; // json string with query params as serialized by spike
}

const QueriesTable = (props: Props) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([]);
    const [selectedRows, setSelectedRows] = useState<Array<QueryTableData>>([]);
    const hasSelected = selectedRowKeys.length > 0;
    const handleDelete = key => {
        const dataSource = [...props.queriesData];
        const deletedQueryId = dataSource.find(item => item.key === key).queryId;
        db.queriesTable
            .where('queryId')
            .equals(deletedQueryId)
            .delete();
        props.setQueriesData(dataSource.filter(item => item.key !== key));
    };

    const deleteMultipleRows = keys => {
        const dataSource = [...props.queriesData];
        const removedRows = dataSource.filter(item => keys.has(item.key));
        console.log(keys);
        console.log(removedRows);
        for (const removedRow of removedRows) {
            const deletedQueryId = removedRow.queryId;
            db.queriesTable
                .where('queryId')
                .equals(deletedQueryId)
                .delete();
        }

        props.setQueriesData(dataSource.filter(item => !keys.has(item.key)));
    };

    const getQueries = (queryMap: Map<string, QueryProps>): Array<QueryProps> => {
        const mainQuery = queryMap.get('main');
        const subQueries = [...queryMap].filter(([k, v]) => k !== 'main').map(([k, v]) => v);
        const queries = [mainQuery, ...subQueries];
        return queries;
    };

    const queryTableColumns = [
        {
            title: 'Query Type',
            dataIndex: 'queries',
            key: 'type',
            render: queries => (
                <div>
                    {getQueries(queries).map((q, idx) => (
                        <div>
                            <a>{idx > 0 ? q.qtype + ' subquery' : q.qtype}</a>
                        </div>
                    ))}
                </div>
            )
        },
        {
            title: 'Query',
            dataIndex: 'queries',
            key: 'query',
            render: queries => (
                <div>
                    {getQueries(queries).map(q => (
                        <div>
                            <a>{q.query}</a>
                        </div>
                    ))}
                </div>
            )
        },
        {
            title: 'Filters',
            dataIndex: 'queries',
            key: 'filters',
            render: queries => (
                <div>
                    {getQueries(queries).map(q => (
                        <div>
                            <a>{q.filters !== '' ? q.filters : 'NA'}</a>
                        </div>
                    ))}
                </div>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'originalURL',
            key: 'originalURL',
            render: (text, record) => (
                <span>
                    <a href={text} target="_blank">
                        <CaretRightOutlined />
                    </a>{' '}
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                        <a>
                            <DeleteOutlined />
                        </a>
                    </Popconfirm>
                </span>
            )
        }
    ];

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        console.log('selectedRow changed: ', selectedRows);
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
        props.setSelectedRows(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    const deleteSelectionClicked = () => {
        deleteMultipleRows(new Set(selectedRows.map(r => r.key)));
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    return (
        <>
            <Space>
                <Button onClick={deleteSelectionClicked} disabled={!hasSelected}>
                    <DeleteOutlined />
                </Button>
            </Space>
            <Table
                rowSelection={rowSelection}
                columns={queryTableColumns}
                dataSource={props.queriesData}
                loading={props.loading}
            />
        </>
    );
};

export default QueriesTable;
