import EditProjectDialog from './EditProjectDialog';
import NewRelationDialog from './NewRelationDialog';
import { Button, Typography, PageHeader } from 'antd';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Project } from '../api';
const { Text } = Typography;

interface AppHeaderProps {
    project?: Project;
}

const AppHeader = (props: AppHeaderProps) => {
    const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
    const showProjectControls =
        typeof props.project !== 'undefined' &&
        isAuthenticated &&
        props.project.editors.includes(user.email);
    return (
        <PageHeader
            className="site-page-header"
            title={
                <h2>
                    <a href="/">SPIKE-KBC</a>
                </h2>
            }
            subTitle={
                typeof props.project !== 'undefined' ? (
                    <h3>
                        <Text type="secondary">{props.project.displayName}</Text>
                    </h3>
                ) : (
                    ''
                )
            }
            extra={[
                isAuthenticated && !showProjectControls ? (
                    <EditProjectDialog
                        disabled={false}
                        buttonLabel="New Project"
                        creatingUserEmail={user.email}
                    />
                ) : (
                    <></>
                ),
                isAuthenticated && showProjectControls ? (
                    <EditProjectDialog
                        project={props.project}
                        disabled={!showProjectControls}
                        buttonLabel="Edit Project"
                    />
                ) : (
                    <></>
                ),
                isAuthenticated && showProjectControls ? (
                    <NewRelationDialog projectName={`${props.project.name}`} />
                ) : (
                    <></>
                ),
                !isAuthenticated ? (
                    <Button
                        onClick={() => {
                            loginWithRedirect({ appState: { target: window.location.pathname } });
                        }}>
                        Log In
                    </Button>
                ) : (
                    <Button
                        onClick={() => {
                            logout({ returnTo: window.location.origin });
                        }}>
                        Log Out
                    </Button>
                )
            ]}
            // breadcrumb={{ routes: routesAlt, itemRender: itemRender }}
        />
    );
};

export default AppHeader;
