import axios from 'axios';
import * as stream from 'stream';

interface Span {
    first: number;
    last: number;
}

interface Capture {
    name: string;
    span: Span;
}
export interface SpikeQueryResult {
    words: Array<string>;
    captures: Array<Capture>;
    paperTitle: string;
    paperYear: number;
    paperId: string;
}

export interface WordAndScore {
    word: string;
    score: number;
}

export interface RelationInstance {
    entity1Type: string;
    entity1Name: string;
    relationLabel: string;
    entity2Type: string;
    entity2Name: string;
    refSentence: string;
    refTitle: string;
    refId: string;
    refYear: number;
    annotator: string;
}

export interface KBPivotItem {
    drug: string;
    biomaterial: string;
    ligand: string;
    target: string;
    cancer: string;
}

export interface RelationSpecs {
    e1Type: string;
    label: string;
    e2Type: string;
}

export interface Project {
    name: string; // a short name, no spaces, same as the database name
    displayName: string; // a more descriptive name, can include spaces, etc.
    description: string;
    editors: Array<string>;
    contacts: Array<string>;
    isPublic: boolean;
    relationSpecs?: Array<RelationSpecs>;
}

const baseURL = 'https://spike.apps.allenai.org';

export const createProject = async (
    name: string,
    displayName: string,
    description: string,
    contributors: Array<string>,
    contacts: Array<string>,
    isPublic: boolean
) => {
    const results = await axios.post(`/api/projects/${name}`, {
        name: name,
        display_name: displayName,
        description: description,
        contributors: contributors,
        contacts: contacts,
        is_public: isPublic
    });
    return results.data;
};

export const modifyProject = async (
    name: string,
    displayName: string,
    description: string,
    contributors: Array<string>,
    contacts: Array<string>,
    isPublic: boolean
) => {
    const results = await axios.put(`/api/projects/${name}`, {
        name: name,
        display_name: displayName,
        description: description,
        contributors: contributors,
        contacts: contacts,
        is_public: isPublic
    });
    return results.data;
};

export const addRelationToProject = async (
    projectName: string,
    e1Type: string,
    relLabel: string,
    e2Type: string
) => {
    const results = await axios.post(`/api/projects/${projectName}/relation_specs`, {
        e1_type: e1Type,
        label: relLabel,
        e2_type: e2Type
    });
    return results.data;
};

export const chainPairwiseCounts = async (
    projectName: string,
    entTypes: Array<string>
): Promise<Array<Map<string, number>>> => {
    const results = await axios.post(`/api/projects/${projectName}/chain_pairwise`, {
        ent_types: entTypes
    });
    const res = [];
    for (const typePairCounts of results.data) {
        const typePairMap = new Map();
        for (const entPairCounts of typePairCounts) {
            const [ent1Name, ent2Name] = entPairCounts[0];
            const count = entPairCounts[1];
            typePairMap.set([ent1Name, ent2Name].join('***'), count);
        }
        res.push(typePairMap);
    }
    return res;
};

/**
 * {
  "queries": {
    "main": {
      "boolean": "infection :{drugs}",
      "parent": " +paragraph:(\"sars\")",
      "expansion": ""
    }
  },
  "data_set_name": "pubmed",
  "context": {
    "lists": {
      "drugs": [
        "remdesivir",
        "cloroquine"
      ]
    },
    "tables": {

    },
    "case_strategy": "ignore",
    "attempt_fuzzy": false
  }
}
 * @param query
 * @param maxResults
 * @param dataset
 */
export const runPatternSpikeAPI = async (
    query: { [name: string]: any },
    maxResults: number,
    includeSentence: boolean,
    includeSyntax: boolean
): Promise<Array<SpikeQueryResult>> => {
    const ret = [];
    const results = await fetch(`${baseURL}/api/3/multi-search/query`, {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(query)
    });

    const streamURL = new Map(results.headers).get('stream-location');
    console.log(streamURL);
    const params = {
        include_sentence: includeSentence ? 'true' : 'false',
        limit: maxResults.toString(),
        include_syntax: includeSyntax ? 'true' : 'false',
        include_metadata: 'title,year,pmid_,pmid,journal_title'
    };
    console.log(query);
    const url = new URL(baseURL + streamURL);
    url.search = new URLSearchParams(params).toString();
    const r = await fetch(url.href);
    // eslint-disable-next-line
    const reader = r.body.pipeThrough(new TextDecoderStream()).getReader();
    let fragment = '';
    while (true) {
        console.log('reading chunk');
        const { value, done } = await reader.read();
        if (done) {
            console.log('done reading stream');
            break;
        }
        const lines = value.split('\n');
        // console.log(lines);
        lines[0] = fragment + lines[0];
        for (const [idx, item] of lines.entries()) {
            try {
                if (item) {
                    const itemJson = JSON.parse(item);
                    if (itemJson.kind === 'multi-match') {
                        const words = [];
                        const capturesArr = [];
                        for (const [key, value] of Object.entries(itemJson.value.sub_matches)) {
                            const resJson = itemJson.value.sub_matches[key];
                            const newSent = JSON.stringify(words) !== JSON.stringify(resJson.words);
                            if (
                                Object.keys(resJson.captures).includes('arg1') ||
                                Object.keys(resJson.captures).includes('arg2')
                            ) {
                                const captures = Object.entries(resJson.captures)
                                    .filter(([name, span]) => name === 'arg1' || name === 'arg2')
                                    .map(([name, span]) => {
                                        return {
                                            name: name,
                                            span: {
                                                // @ts-ignore
                                                first:
                                                    span.first +
                                                    (newSent && words.length > 0
                                                        ? words.length + 1
                                                        : 0),
                                                // @ts-ignore
                                                last:
                                                    span.last +
                                                    (newSent && words.length > 0
                                                        ? words.length + 1
                                                        : 0)
                                            }
                                        };
                                    });
                                const capturesSorted = captures.sort((a, b) =>
                                    a.name.localeCompare(b.name)
                                );
                                capturesArr.push(...capturesSorted);
                                if (newSent) {
                                    if (words.length > 0) {
                                        words.push('<SEP>');
                                    }
                                    words.push(...resJson.words);
                                }
                            }
                        }
                        const resJson = itemJson.value.sub_matches.main;
                        const normWords = words.map(w => w.normalize('NFKD'));
                        if (
                            capturesArr.every(
                                c =>
                                    normWords
                                        .slice(c.span.first, c.span.last + 1)
                                        .join(' ')
                                        .trim() !== ''
                            )
                        ) {
                            ret.push({
                                words: normWords,
                                captures: capturesArr,
                                paperTitle: resJson.metadata.title,
                                paperYear: resJson.metadata.year,
                                paperId:
                                    'pmid' in resJson.metadata
                                        ? resJson.metadata.pmid
                                        : resJson.metadata.pmid_
                            });
                        }

                        // const resJson = itemJson.value.sub_matches.main;
                        // const captures = Object.entries(resJson.captures).map(([name, span]) => {
                        //     return { name: name, span: span };
                        // });
                        // const capturesSorted = captures.sort((a, b) =>
                        //     a.name.localeCompare(b.name)
                        // );
                    } else {
                        console.log(itemJson.kind);
                    }
                }
            } catch (error) {
                fragment = lines[idx];
            }
        }
    }
    return ret;
    // return results.data.map(result => {
    //     const captures = Object.entries(result[1].captures).map(([name, span]) => {
    //         return { name: name, span: span };
    //     });
    //     const capturesSorted = captures.sort((a, b) => a.name.localeCompare(b.name));
    //     const res = {
    //         words: result[1].words,
    //         captures: capturesSorted,
    //         paperTitle: result[1].metadata.title,
    //         paperYear: result[1].metadata.year,
    //         paperId:
    //             'pmid' in result[1].metadata ? result[1].metadata.pmid : result[1].metadata.pmid_
    //     };
    //     return res;
    // });
};

/**
 * {
  "queries_dict": {
    "syntactic": "arg2:[w=`sciatica`|`Sciatic Neuralgia`|`sciatic pain`|`ischias`]sciatica $caused by <>arg1:something",
    "parent": "",
    "case_strategy": "ignore"
  },
  "max_results": 5000,
  "dataset": "pubmed"
}
 * @param query
 * @param maxResults
 * @param dataset
 */
export const runPattern = async (
    query: { [name: string]: any },
    maxResults: number,
    dataset: string
): Promise<Array<SpikeQueryResult>> => {
    const results = await axios.post('/api/run_pattern', {
        queries_dict: query,
        max_results: maxResults,
        dataset: dataset
    });

    return results.data.map(result => {
        const captures = Object.entries(result[1].captures).map(([name, span]) => {
            return { name: name, span: span };
        });
        const capturesSorted = captures.sort((a, b) => a.name.localeCompare(b.name));
        const res = {
            words: result[1].words,
            captures: capturesSorted,
            paperTitle: result[1].metadata.title,
            paperYear: result[1].metadata.year,
            paperId:
                'pmid' in result[1].metadata ? result[1].metadata.pmid : result[1].metadata.pmid_
        };
        return res;
    });
};

export const kbPivotTable = async (project: string): Promise<Array<KBPivotItem>> => {
    const results = await axios.get('/api/kb_pivot', { params: { project: project } });
    console.log(results.data);
    return results.data;
};

export const listProjects = async (): Promise<Array<Project>> => {
    const projectObjs = await axios.get(`/api/projects`);
    const projects: Array<Project> = [];
    for (const projectObj of projectObjs.data) {
        projects.push({
            name: projectObj.name,
            displayName: projectObj.display_name,
            description: projectObj.description,
            editors: projectObj.contributors,
            contacts: projectObj.contacts,
            isPublic: projectObj.is_public
        });
    }
    return projects;
};

export const getProject = async (projectName: string): Promise<Project> => {
    const projectObj = (
        await axios.get(`/api/projects/${projectName}?timestamp=${new Date().getTime()}`)
    ).data;
    const relationSpecIds = await axios.get(`/api/projects/${projectName}/relation_specs`);
    return {
        name: projectObj.name,
        displayName: projectObj.display_name,
        description: projectObj.description,
        editors: projectObj.contributors,
        contacts: projectObj.contacts,
        isPublic: projectObj.is_public,
        relationSpecs: relationSpecIds.data.map(rs => ({
            e1Type: rs.e1_type,
            label: rs.label,
            e2Type: rs.e2_type
        }))
    };
};

export const entityForType = async (entityType: string, project: string) => {
    const results = await axios.get('/api/entity_for_type', {
        params: { e_type: entityType, project: project }
    });
    return results.data;
};

export const similarEntities = async (
    entityType: string,
    entityName: string,
    project: string
): Promise<Array<string>> => {
    const results = await axios.get('/api/similar_entities', {
        params: { e_type: entityType, e_name: entityName, project: project }
    });
    return results.data;
};

export const login = async (): Promise<string> => {
    const results = await axios.get('/api/secure', {});
    return results.data;
};

export const relationInstances = async (
    entity1Type: string,
    relationLabel: string,
    entity2Type: string,
    project: string
): Promise<Array<RelationInstance>> => {
    const results = await axios.get('/api/relation_instances', {
        params: {
            e1_type: entity1Type,
            r_label: relationLabel,
            e2_type: entity2Type,
            project: project
        }
    });
    return results.data.map(r => ({
        entity1Type: entity1Type,
        entity1Name: r[0],
        relationLabel: relationLabel,
        entity2Type: entity2Type,
        entity2Name: r[1],
        refSentence: r[2],
        refTitle: r[3],
        refId: r[4],
        refYear: r[5],
        annotator: r[6]
    }));
};
export const removeRelation = async (
    entity1Type: string,
    entity1Name: string,
    relation: string,
    entity2Type: string,
    entity2Name: string,
    referenceSentence: string,
    referenceTitle: string,
    referencePMID: string,
    username: string,
    project: string
) => {
    const results = await axios.post('/api/remove_relation', {
        e1_type: entity1Type,
        e1_name: entity1Name,
        e1_alias: '',
        r_type: relation,
        e2_type: entity2Type,
        e2_name: entity2Name,
        e2_alias: '',
        ref_sentence: referenceSentence,
        ref_title: referenceTitle,
        ref_pmid: referencePMID,
        ref_year: 0,
        username: username,
        project: project
    });
    return results;
};
export const addRelation = async (
    entity1Type: string,
    entity1Alias: string,
    entity1Name: string,
    relation: string,
    entity2Type: string,
    entity2Alias: string,
    entity2Name: string,
    referenceSentence: string,
    referenceTitle: string,
    referencePMID: string,
    referenceYear: number,
    username: string,
    project: string
) => {
    const results = await axios.post('/api/add_relation', {
        e1_type: entity1Type,
        e1_alias: entity1Alias,
        e1_name: entity1Name,
        r_type: relation,
        e2_type: entity2Type,
        e2_alias: entity2Alias,
        e2_name: entity2Name,
        ref_sentence: referenceSentence,
        ref_title: referenceTitle,
        ref_pmid: referencePMID,
        ref_year: referenceYear,
        username: username,
        project: project
    });
    return results;
};

export const renameEntity = async (
    sourceType: string,
    sourceName: string,
    destName: string,
    project: string
) => {
    const results = await axios.post('/api/rename_entity', {
        source_type: sourceType,
        source_name: sourceName,
        dest_name: destName,
        project: project
    });
    return results;
};

export type ListDesciptor = {
    id: string;
    items: Array<string>;
};

export async function downloadUserList(id: string): Promise<ListDesciptor> {
    const response = await axios.get(`/api/get_list`, { params: { list_id: id } });
    return { id: response.data.id, items: response.data.items };
}
