import { Button, Col, Input, notification, Row, Space, Spin } from 'antd';
import QueriesTable, { QueryProps, QueryTableData, QueryTypeVals } from './QueriesTable';
import QueryResultsTable, {
    enforceMaxResultsPerEntityPair,
    QueryResultsTableData,
    runQueries
} from './QueryResultsTable';
import React, { useEffect, useRef, useState } from 'react';
import { downloadUserList, Project, RelationInstance, RelationSpecs } from '../api';
import { decodeFromSearchHash } from '../bl/inputs-encoding';
import { covertToAPIQueries, Query } from '../bl/query-conversion';
import md5 from 'md5';
import { useAuth0 } from '@auth0/auth0-react';
import { db } from '../bl';
import RelationSelection from './RelationSelection';
import { v4 as uuid } from 'uuid';
import hash from 'object-hash';
import SettingsDialog from './SettingDialog';
import { mapReplacer, mapReviver } from '../utils';
import CopyQueryHelp from './CopyQueryHelp';
interface AnnotatePageProps {
    project: Project;
    relationInstancesMap: {
        [k: string]: Array<RelationInstance>;
    };
    setUpdateRelationInstancesMap: (updateRelationInstancesMap: string) => void;
    loading: boolean;
}

const AnnotatePage = (props: AnnotatePageProps) => {
    const dummyQueryData = {
        key: 1,
        queryId: uuid(),
        queries: new Map(),
        originalURL: '',
        originalQueryParams: '',
        caseStrategy: ''
    };
    const { user, isAuthenticated } = useAuth0();
    const [spikeURL, setSpikeURL] = useState<string>('');
    const [queriesData, setQueriesData] = useState<Array<QueryTableData>>([]);
    const [selectedQueriesData, setSelectedQueriesData] = useState<Array<QueryTableData>>([]);
    const [loading, setLoading] = useState(false);
    const [queriesLoading, setQueriesLoading] = useState(false);
    const [posEntityPairsHash, setPosEntityPairsHash] = useState(new Set<string>());
    const [negEntityPairsHash, setNegEntityPairsHash] = useState(new Set<string>());
    const [posExamplesHash, setPosExamplesHash] = useState(new Set<string>());
    const [negExamplesHash, setNegExamplesHash] = useState(new Set<string>());
    const [filterByEntityPairs, setFilterByEntityPairs] = useState<boolean>(false);
    const selectedHyponyms = useRef(new Set<string>());
    const [selectedHyponymRows, setSelectedHyponymRows] = useState<Array<number>>([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
    const [maxResultsPerQuery, setMaxResultsPerQuery] = useState<number>(5000);
    const [maxResultsPerEntityPair, setMaxResultsPerEntityPair] = useState<number>(50);
    const [selectedDataset, setSelectedDataset] = useState<string>('pubmed');
    const [originalQueryResultsData, setOriginalQueryResultsData] = useState<
        Array<QueryResultsTableData>
    >([]);
    const [queryResultsData, setQueryResultsData] = useState<Array<QueryResultsTableData>>([]);
    const [removedExamples, setRemovedExamples] = useState<Set<string>>(new Set());
    const [selectedRelationSpecs, setSelectedRelationSpecs] = useState<RelationSpecs>();

    const loadQueriesForRelation = (relation: string) => {
        db.queriesTable
            .where('relation')
            .equals(relation)
            .toArray()
            .then(queryDataArray =>
                queryDataArray.map(qd => ({
                    key: qd.id,
                    queryId: qd.queryId,
                    queries: JSON.parse(qd.queries, mapReviver),
                    originalURL: qd.originalURL,
                    originalQueryParams: qd.originalQueryParams,
                    caseStrategy: qd.caseStrategy
                }))
            )
            .then(res => setQueriesData(res));
    };

    const handleRelationSpecsChange = (value: RelationSpecs) => {
        const relation = `${value.e1Type}-${value.label}-${value.e2Type}`;
        setSelectedRelationSpecs(value);
        loadQueriesForRelation(relation);
        setPagination({ ...pagination, total: 0 });
        setOriginalQueryResultsData([]);
        setQueryResultsData([]);
    };

    const handleRelationSpecsChangeStr = (relation: string) => {
        const toks = relation.split('-');
        handleRelationSpecsChange({ e1Type: toks[0], label: toks[1], e2Type: toks[2] });
    };

    useEffect(() => {
        if (typeof props.project !== 'undefined' && props.project.relationSpecs.length > 0) {
            setSelectedRelationSpecs(props.project.relationSpecs[0]);
            handleRelationSpecsChange(props.project.relationSpecs[0]);
        }
    }, [props.project]);

    const toNegRelationSpec = (relationSpecs: string) => {
        const toks = relationSpecs.split('-');
        return `NEG_${toks[0]}-NEG_${toks[1]}-NEG_${toks[2]}`;
    };

    const relInstToEntNames = (relInst: RelationInstance): [string, string] => {
        const entity1NameMatch = relInst.refSentence.match(new RegExp('<e1>(.*?)</e1>'));
        const entity1Name =
            entity1NameMatch !== null ? entity1NameMatch[1].trim() : relInst.entity1Name;
        const entity2NameMatch = relInst.refSentence.match(new RegExp('<e2>(.*?)</e2>'));
        const entity2Name =
            entity2NameMatch !== null ? entity2NameMatch[1].trim() : relInst.entity2Name;
        return [entity1Name, entity2Name];
    };

    useEffect(() => {
        if (
            typeof selectedRelationSpecs !== 'undefined' &&
            Object.keys(props.relationInstancesMap).length > 0
        ) {
            const relationStr = `${selectedRelationSpecs.e1Type}-${selectedRelationSpecs.label}-${selectedRelationSpecs.e2Type}`;
            const negRelationStr = toNegRelationSpec(relationStr);
            setPosEntityPairsHash(
                new Set(
                    props.relationInstancesMap[relationStr].map(relInst => {
                        const [entity1Name, entity2Name] = relInstToEntNames(relInst);
                        return hash([entity1Name.toLowerCase(), entity2Name.toLowerCase()]);
                    })
                )
            );
            setPosExamplesHash(
                new Set(
                    props.relationInstancesMap[relationStr].map(relInst => {
                        const [entity1Name, entity2Name] = relInstToEntNames(relInst);
                        const key = [
                            entity1Name,
                            entity2Name,
                            relInst.refSentence,
                            relInst.refTitle
                        ];
                        return hash(key);
                    })
                )
            );
            setNegEntityPairsHash(
                new Set(
                    props.relationInstancesMap[negRelationStr].map(relInst => {
                        const [entity1Name, entity2Name] = relInstToEntNames(relInst);
                        return hash([entity1Name.toLowerCase(), entity2Name.toLowerCase()]);
                    })
                )
            );
            setNegExamplesHash(
                new Set(
                    props.relationInstancesMap[negRelationStr].map(relInst => {
                        const [entity1Name, entity2Name] = relInstToEntNames(relInst);
                        return hash([
                            entity1Name,
                            entity2Name,
                            relInst.refSentence,
                            relInst.refTitle
                        ]);
                    })
                )
            );
        }
    }, [props.relationInstancesMap, selectedRelationSpecs]);

    const getEntityPair = (result: QueryResultsTableData) => {
        const entity1Name = result.reference.text.match(new RegExp('<e1>(.*?)</e1>'))[1].trim();
        const entity2Name = result.reference.text.match(new RegExp('<e2>(.*?)</e2>'))[1].trim();
        return [entity1Name.toLowerCase(), entity2Name.toLowerCase()];
    };
    const getExample = (result: QueryResultsTableData) => {
        const entity1Name = result.reference.text
            .match(new RegExp('<e1>([\\s\\S]*)</e1>'))[1]
            .trim();
        const entity2Name = result.reference.text
            .match(new RegExp('<e2>([\\s\\S]*)</e2>'))[1]
            .trim();
        const key = [entity1Name, entity2Name, result.reference.text, result.reference.title];
        return key;
    };

    async function runQueriesBtnClick(selectedQueries: Array<QueryTableData>) {
        setLoading(true);
        setSelectedHyponymRows([]);
        const queries = selectedQueries.map(q => JSON.parse(q.originalQueryParams));

        const newData = await runQueries(queries, maxResultsPerQuery);
        setPagination({ ...pagination, total: Object.keys(newData).length });
        setOriginalQueryResultsData(newData);

        const posItemsToRemove = filterByEntityPairs ? posEntityPairsHash : posExamplesHash;
        const negItemsToRemove = filterByEntityPairs ? negEntityPairsHash : negExamplesHash;
        const itemsGetter: (result: QueryResultsTableData) => Array<string> = filterByEntityPairs
            ? getEntityPair
            : getExample;
        const filteredData = enforceMaxResultsPerEntityPair(
            newData.filter(
                v =>
                    !posItemsToRemove.has(hash(itemsGetter(v))) &&
                    !negItemsToRemove.has(hash(itemsGetter(v))) &&
                    !removedExamples.has(md5(v.reference.id + v.reference.text))
            ),
            maxResultsPerEntityPair
        );

        setQueryResultsData(filteredData);
        setLoading(false);
    }

    function findAllMatches(regex: RegExp, text: string): Array<RegExpExecArray> {
        const results: Array<RegExpExecArray> = [];
        let match: RegExpExecArray | null;
        while (true) {
            match = regex.exec(text);
            if (match === null) {
                break;
            } else {
                results.push(match);
            }
        }
        return results;
    }

    async function expandLists(inputQuery: string): Promise<string> {
        const USER_LIST_VAR_PATTERN = /\{([^:}]*):([^:}]*)\}/g;
        const REGEX_RANGE_PATTERN = /^\d+[-,]\d+$/;
        const listVarMatches = findAllMatches(USER_LIST_VAR_PATTERN, inputQuery);
        if (listVarMatches.length < 1) {
            return inputQuery;
        }

        const queryReplacements: Map<string, string> = new Map();

        for (const match of listVarMatches) {
            const listName = match[1];
            const listId = match[2];
            // validate no {}
            if (listName === '') {
                continue;
            }

            // ignore curly bracket uses of the token quantifier syntax
            if (listName.match(REGEX_RANGE_PATTERN) !== null) {
                continue;
            }

            const list = (await downloadUserList(listId)).items; // download per id

            queryReplacements.set(listName, list.map(item => ['`', item, '`'].join('')).join('|'));
        }
        // substitute list vars with values
        let modifiedQuery = inputQuery;
        for (const [listName, substitution] of queryReplacements.entries()) {
            const replacementRegex = new RegExp(`{${listName}:.*?}`, 'g');
            modifiedQuery = modifiedQuery.replace(replacementRegex, substitution);
        }
        return modifiedQuery;
    }

    async function expandFilterLists(inputQuery: string): Promise<string> {
        const USER_LIST_VAR_PATTERN = /\{([^:}]*):([^:}]*)\}/g;
        const REGEX_RANGE_PATTERN = /^\d+[-,]\d+$/;
        const listVarMatches = findAllMatches(USER_LIST_VAR_PATTERN, inputQuery);
        if (listVarMatches.length < 1) {
            return inputQuery;
        }

        const queryReplacements: Map<string, string> = new Map();

        for (const match of listVarMatches) {
            const listName = match[1];
            const listId = match[2];
            // validate no {}
            if (listName === '') {
                continue;
            }

            // ignore curly bracket uses of the token quantifier syntax
            if (listName.match(REGEX_RANGE_PATTERN) !== null) {
                continue;
            }

            const list = (await downloadUserList(listId)).items; // download per id

            queryReplacements.set(listName, list.map(item => ['"', item, '"'].join('')).join(' '));
        }
        // substitute list vars with values
        let modifiedQuery = inputQuery;
        for (const [listName, substitution] of queryReplacements.entries()) {
            const replacementRegex = new RegExp(`"{${listName}:.*?}"`, 'g');
            modifiedQuery = modifiedQuery.replace(replacementRegex, substitution);
        }
        return modifiedQuery;
    }

    const addQueriesToQueriesTable = (inputQueries: Array<QueryTableData>) => {
        const queries = queriesData.slice();
        const queriesSet = new Set(queries.map(q => q.queryId));
        const queriesToAdd = inputQueries.filter(q => !queriesSet.has(q.queryId));
        for (const query of queriesToAdd) {
            if (
                !(
                    Array.from(query.queries.values()).some(q => q.query.includes('arg1')) &&
                    Array.from(query.queries.values()).some(q => q.query.includes('arg2'))
                )
            ) {
                notification.open({
                    message: `The query must include two captures named arg1 and arg2: ${query}`
                });
                setQueriesLoading(false);
                return;
            }
        }
        for (const query of queriesToAdd) {
            const queryObj = {
                ...query,
                queries: JSON.stringify(query.queries, mapReplacer),
                relation: `${selectedRelationSpecs.e1Type}-${selectedRelationSpecs.label}-${selectedRelationSpecs.e2Type}`
            };
            db.queriesTable.add(queryObj);
        }
        console.log('here1');
        setQueriesData([...queries, ...queriesToAdd]);
    };

    const addQueryToQueriesTable = (query: QueryTableData) => {
        addQueriesToQueriesTable([query]);
    };

    const createQueriesMap = async (queries: { [name: string]: any }) => {
        const ret = new Map<string, QueryProps>();
        for (const filterId of Object.keys(queries)) {
            let qtype;
            let query = '';
            let filters = '';
            if (Object.prototype.hasOwnProperty.call(queries[filterId], 'syntactic')) {
                qtype = 'syntactic';
                query = queries[filterId].syntactic;
            } else if (Object.prototype.hasOwnProperty.call(queries[filterId], 'token')) {
                qtype = 'token';
                query = queries[filterId].token;
            } else if (Object.prototype.hasOwnProperty.call(queries[filterId], 'boolean')) {
                qtype = 'boolean';
                query = queries[filterId].boolean;
            }
            query = query.replace(/\{([^:}]*):([^:}]*)\}/g, '{$1}');

            if (Object.prototype.hasOwnProperty.call(queries[filterId], 'parent')) {
                filters = queries[filterId].parent.replace(/\{([^:}]*):([^:}]*)\}/g, '{$1}');
            }

            ret.set(filterId, {
                query: query,
                qtype: qtype,
                filters: filters
            });
        }
        return ret;
    };

    const addQueryStringToQueriesTable = async (q: { [name: string]: any }) => {
        setQueriesLoading(true);

        console.log(q.originalURL);
        try {
            const queryMap = await createQueriesMap(q.request.queries);
            console.log(queryMap);

            const finalQuery = {
                key: queriesData.length > 0 ? queriesData[queriesData.length - 1].key + 1 : 0,
                queryId: uuid(),
                queries: queryMap,
                originalURL: q.originalURL,
                originalQueryParams: JSON.stringify(q.request),
                caseStrategy: q.request.context.case_strategy
            };
            addQueryToQueriesTable(finalQuery);
            setSpikeURL('');
            setQueriesLoading(false);
        } catch (err) {
            notification.open({
                message: 'Failed to read clipboard contents: ' + err
            });
            setQueriesLoading(false);
        }
    };

    const addQueryFromClipboardClicked = async () => {
        addQueryStringToQueriesTable(JSON.parse(spikeURL));
    };

    const onSpikeURLChange = e => {
        setSpikeURL(e.target.value);
    };

    const updateHyponymsTable = () => {
        const posItemsToRemove = filterByEntityPairs ? posEntityPairsHash : posExamplesHash;
        const negItemsToRemove = filterByEntityPairs ? negEntityPairsHash : negExamplesHash;
        const itemsGetter: (result: QueryResultsTableData) => Array<string> = filterByEntityPairs
            ? getEntityPair
            : getExample;
        setQueryResultsData(
            enforceMaxResultsPerEntityPair(
                originalQueryResultsData.filter(
                    v =>
                        !posItemsToRemove.has(hash(itemsGetter(v))) &&
                        !negItemsToRemove.has(hash(itemsGetter(v))) &&
                        !removedExamples.has(md5(v.reference.id + v.reference.text))
                ),
                maxResultsPerEntityPair
            )
        );
    };

    useEffect(() => {
        updateHyponymsTable();
    }, [posEntityPairsHash, negEntityPairsHash, posExamplesHash, negExamplesHash, removedExamples]);

    const importQueries = async () => {
        // @ts-ignore
        const [fileHandle] = await window.showOpenFilePicker();
        const file = await fileHandle.getFile();
        const contents = await file.text();
        const queriesToAdd = contents.split('\n').map(l => JSON.parse(l, mapReviver));
        addQueriesToQueriesTable(queriesToAdd);
    };

    async function getNewFileHandle() {
        const options = {
            suggestedName: 'queries.json',
            types: [
                {
                    description: 'Json Files',
                    accept: {
                        'application/json': ['.json']
                    }
                }
            ]
        };
        // @ts-ignore
        const handle = await window.showSaveFilePicker(options);
        return handle;
    }

    async function writeFile(fileHandle, contents) {
        // Create a FileSystemWritableFileStream to write to.
        const writable = await fileHandle.createWritable();
        // Write the contents of the file to the stream.
        await writable.write(contents);
        // Close the file and write the contents to disk.
        await writable.close();
    }

    async function exportQueries() {
        const fileHandle = await getNewFileHandle();
        // Create a FileSystemWritableFileStream to write to.
        const writable = await fileHandle.createWritable();
        // Write the contents of the file to the stream.
        await writable.write(queriesData.map(q => JSON.stringify(q, mapReplacer)).join('\n'));
        // Close the file and write the contents to disk.
        await writable.close();
    }
    const selectedRelationExists = typeof selectedRelationSpecs !== 'undefined';
    return (
        <>
            <>
                <Row gutter={[24, 24]}>
                    <Col span={8}>
                        {typeof props.project === 'undefined' ? (
                            <Spin />
                        ) : (
                            <RelationSelection
                                project={props.project}
                                handleRelationSpecsChange={handleRelationSpecsChangeStr}
                            />
                        )}
                    </Col>
                    <Col span={8} offset={8}>
                        <Row justify="end">
                            <Col>
                                <Button style={{ marginRight: '8px' }} onClick={exportQueries}>
                                    Export Queries
                                </Button>
                            </Col>
                            <Col>
                                <Button style={{ marginRight: '8px' }} onClick={importQueries}>
                                    Import Queries
                                </Button>
                            </Col>
                            <Col>
                                <SettingsDialog
                                    maxResultsPerQuery={maxResultsPerQuery}
                                    setMaxResultsPerQuery={setMaxResultsPerQuery}
                                    maxResultsPerEntityPair={maxResultsPerEntityPair}
                                    setMaxResultsPerEntityPair={setMaxResultsPerEntityPair}
                                    filterByEntityPair={filterByEntityPairs}
                                    setFilterByEntityPair={setFilterByEntityPairs}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={4}>
                        <Input
                            placeholder={
                                selectedRelationExists
                                    ? 'Paste SPIKE Query here...'
                                    : 'Add a relation by clicking the top right button'
                            }
                            value={spikeURL}
                            onChange={onSpikeURLChange}
                            disabled={!selectedRelationExists}
                        />
                    </Col>
                    <Col span={6}>
                        <Space>
                            <Button
                                onClick={addQueryFromClipboardClicked}
                                className="control"
                                disabled={!selectedRelationExists}>
                                Add SPIKE Query
                            </Button>
                            <CopyQueryHelp />
                        </Space>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <QueriesTable
                            loading={queriesLoading}
                            queriesData={queriesData}
                            setSelectedRows={setSelectedQueriesData}
                            setQueriesData={setQueriesData}></QueriesTable>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={24}>
                        <Button
                            type="primary"
                            onClick={() => runQueriesBtnClick(queriesData)}
                            disabled={
                                typeof selectedRelationSpecs === 'undefined' &&
                                queriesData.length > 0
                            }>
                            Run Queries
                        </Button>
                        <Button
                            type="primary"
                            style={{ marginLeft: '8px' }}
                            onClick={() => runQueriesBtnClick(selectedQueriesData)}
                            disabled={
                                typeof selectedRelationSpecs === 'undefined' &&
                                selectedQueriesData.length > 0
                            }>
                            Run Selected Queries
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <p className="detectedHyponymsCnt">
                            Retrieved {queryResultsData.length} examples
                        </p>
                    </Col>
                </Row>
                <Row>
                    <QueryResultsTable
                        resultsData={queryResultsData}
                        selectedHyponyms={selectedHyponyms}
                        loading={loading || props.loading}
                        selectedRowsArray={selectedHyponymRows}
                        setSelectedRowsArray={setSelectedHyponymRows}
                        setRemovedExamples={setRemovedExamples}
                        entity1Type={
                            typeof selectedRelationSpecs === 'undefined'
                                ? 'NA'
                                : selectedRelationSpecs.e1Type
                        }
                        relationLabel={
                            typeof selectedRelationSpecs === 'undefined'
                                ? 'NA'
                                : selectedRelationSpecs.label
                        }
                        entity2Type={
                            typeof selectedRelationSpecs === 'undefined'
                                ? 'NA'
                                : selectedRelationSpecs.e2Type
                        }
                        username={user.email}
                        setUpdateRelationInstancesMap={props.setUpdateRelationInstancesMap}
                    />
                </Row>
            </>
        </>
    );
};

export default AnnotatePage;
