import { Card, Col, Layout, List, PageHeader, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { listProjects, Project } from '../api';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import './ProjectsPage.css';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import { useAuth0 } from '@auth0/auth0-react';
const { Content, Footer } = Layout;

function ProjectsPage(props: { loggedInUserEmail?: string }) {
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { user, isAuthenticated } = useAuth0();
    useEffect(() => {
        setLoading(true);
        listProjects()
            .then(projects => {
                setProjects(projects);
            })
            .finally(() => setLoading(false));
    }, []);

    const YoutubeEmbed = ({ embedId }) => (
        <div className="video-responsive">
            <iframe
                width="511" // "853"
                height="288" // "480"
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    );

    return (
        <div>
            <Layout>
                <AppHeader />
                <Content>
                    <div className="site-layout-content">
                        <Row>
                            <Col span={10}>
                                <p>
                                    Welcome to <b>SPIKE-KBC</b>, a platform for knowledge base
                                    construction based on the{' '}
                                    <a href="https://spike.apps.allenai.org">
                                        SPIKE extractive search engine
                                    </a>
                                    . You can use SPIKE-KBC to explore existing knowledge bases or
                                    create new ones.
                                </p>

                                <p>
                                    For example, to visualize the{' '}
                                    <b>Cell Specific Drug Delivery (CCDB) </b> knowledge base,
                                    select it from the list below and open its{' '}
                                    <em>Visualization</em> tab. Adding the ordered layers{' '}
                                    <em>Biomaterial</em>, <em>Ligand</em>, <em>Target</em> and{' '}
                                    <em>Cancer</em> will result in a tree view, showing different
                                    sequences of a <em>biomaterial</em> which can connect to a
                                    specific <em>ligand</em> which can deliver a drug to a certain
                                    cellular <em>target</em> related to a specific <em>cancer</em>.
                                </p>
                                <p>
                                    To create a new knowledge base and add instances to it, follow
                                    the video tutorial on the right, or contact us at
                                    spike@allenai.org
                                </p>
                            </Col>

                            <Col offset={2} span={12}>
                                <YoutubeEmbed embedId="zRxnNfdQNF0" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <List
                                    loading={loading}
                                    header={<h2>{'Knowledge Graphs'}</h2>}
                                    itemLayout="horizontal"
                                    dataSource={projects}
                                    renderItem={project =>
                                        project.isPublic ||
                                        (isAuthenticated &&
                                            project.editors.includes(user.email)) ? (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={
                                                        <a href={`/projects/${project.name}`}>
                                                            {project.displayName}
                                                        </a>
                                                    }
                                                    description={project.description}
                                                />
                                                {project.isPublic ? (
                                                    <UnlockOutlined />
                                                ) : (
                                                    <LockOutlined />
                                                )}
                                            </List.Item>
                                        ) : (
                                            <></>
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                    </div>
                </Content>
                <AppFooter />
            </Layout>
        </div>
    );
}

export default ProjectsPage;
