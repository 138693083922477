import { db } from './localdb';
import objectHash from 'object-hash';
import { runPatternSpikeAPI } from '../api';

export interface Reference {
    text: string; // note that the reference text includes tags <e1></e1> and <e2></e2> over entities, so it's a sentence with entities
    title: string;
    id: string;
    year: number;
}

export interface Example {
    pattern: string;
    reference: Reference;
}

type PatternToReferences = { [pattern: string]: Array<Reference> };

export class CaptureInfo {
    capturedElements: Array<string>;
    totalHits: number;
    examples: Array<Example>;

    constructor(capturedElements: Array<string>, totalHits: number, examples: Array<Example>) {
        this.capturedElements = capturedElements;
        this.totalHits = totalHits;
        this.examples = examples;
    }

    referencesByPattern(): PatternToReferences {
        const res = {};
        for (const example of this.examples) {
            if (res[example.pattern] === undefined) {
                res[example.pattern] = [];
            }
            res[example.pattern].push(example.reference);
        }
        return res;
    }
}

export const runPatterns = async (
    queries: Array<{ [name: string]: any }>,
    maxResultsPerQuery: number,
    useCache: boolean = true
): Promise<Array<CaptureInfo>> => {
    console.log(`queries=${queries}`);
    console.log(`maxResultsPerQuery=${maxResultsPerQuery}`);
    console.log(`useCache=${useCache}`);

    const visited = new Set();

    function updateCaptureInfo(
        captureInfo: CaptureInfo,
        capturedElements: Array<string>,
        patternText,
        exampleSentence,
        title,
        pmid,
        year
    ) {
        captureInfo.totalHits += 1;
        captureInfo.examples.push({
            pattern: patternText,
            reference: { text: exampleSentence, title: title, id: pmid, year: year }
        });
        return captureInfo;
    }

    const capturesMap = {};
    for (const query of queries) {
        const patternText = Object.values(query)[0];
        let results = [];

        if (useCache) {
            const cachedResults = await db.queryResults
                .where('query')
                .equals(JSON.stringify(query))
                .toArray();

            console.assert(cachedResults !== null && cachedResults.length <= 1);
            if (cachedResults == null || cachedResults.length === 0) {
                console.log('writing to cache');
                results = await runPatternSpikeAPI(query, maxResultsPerQuery, false, false);
                db.queryResults.put({
                    query: JSON.stringify(query),
                    results: JSON.stringify(results)
                });
            } else {
                console.log('reading from cache');
                results = JSON.parse(cachedResults[0].results);
            }
        } else {
            console.log('running query');
            results = await runPatternSpikeAPI(query, maxResultsPerQuery, false, false);
        }

        console.log(results);
        for (const result of results) {
            const hash = objectHash(result);
            if (!visited.has(hash)) {
                visited.add(hash);
                const capturedElements = result.captures
                    .slice()
                    .sort((cap1, cap2) => cap1.name.localeCompare(cap2.name)) // arg1 should come before arg2
                    .map(c => result.words.slice(c.span.first, c.span.last + 1).join(' '));

                const capturedElementsKey = capturedElements
                    .map(ce => ce.toLowerCase())
                    .join('***');
                if (!(capturedElementsKey in capturesMap)) {
                    capturesMap[capturedElementsKey] = new CaptureInfo(capturedElements, 0, []);
                }

                const words = result.words;
                const sortedCaptures = result.captures.sort(
                    (cap1, cap2) => cap2.span.first - cap1.span.first
                );

                // skip results where the spans of the two arguments overlap
                if (
                    sortedCaptures[0].span.first <= sortedCaptures[1].span.last &&
                    sortedCaptures[1].span.first <= sortedCaptures[1].span.last
                ) {
                    continue;
                }
                for (const capture of sortedCaptures) {
                    words.splice(capture.span.first, 0, capture.name === 'arg1' ? '<e1>' : '<e2>');
                    words.splice(
                        capture.span.last + 2,
                        0,
                        capture.name === 'arg1' ? '</e1>' : '</e2>'
                    );
                }
                const exampleSentence = words
                    .join(' ')
                    .replaceAll('<e1> ', '<e1>')
                    .replaceAll('<e2> ', '<e2>')
                    .replaceAll(' </e1>', '</e1>')
                    .replaceAll(' </e2>', '</e2>');

                capturesMap[capturedElementsKey] = updateCaptureInfo(
                    capturesMap[capturedElementsKey],
                    capturedElements,
                    patternText,
                    exampleSentence,
                    result.paperTitle,
                    result.paperId,
                    result.paperYear
                );
            }
        }
    }
    return Object.values(capturesMap);
};
