import { useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { getProject, Project, RelationInstance, relationInstances } from '../api';
import RelationsTable, { RelationTableData } from './RelationTable';
import { Button, Card, Col, Input, Layout, notification, Row, Tabs, Typography } from 'antd';
import AppHeader from './AppHeader';
import GeneralInformation from './GeneralInformation';
import TreeView from './TreeView';
import NetworkTree from './NetworkTree';
import NameUnification from './NameUnificationWidget';
import AppFooter from './AppFooter';
import md5 from 'md5';
import { v4 as uuid } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';
import AnnotatePage from './AnnotatePage';
const { TabPane } = Tabs;
const { Content } = Layout;

const ProjectPage = () => {
    const projectName = useParams<{ projectName: string; relationId: string }>().projectName;
    const [currProject, setCurrProject] = useState<Project>();
    const [projectRelationInstancesLoading, setProjectRelationInstancesLoading] = useState<boolean>(
        false
    );

    const { isAuthenticated, user } = useAuth0();
    const isProjectEditor =
        typeof currProject !== 'undefined' &&
        isAuthenticated &&
        currProject.editors.includes(user.email);
    const [relationInstancesMap, setRelationInstancesMap] = useState<{
        [k: string]: Array<RelationInstance>;
    }>({});
    const [updateRelationInstancesMap, setUpdateRelationInstancesMap] = useState<string>(uuid());

    useEffect(() => {
        getProject(projectName).then(res => setCurrProject(res));
    }, []);
    const loadRelationInstancesMap = (proj: Project) => {
        console.log('setting spinner');
        setProjectRelationInstancesLoading(true);

        const newRelationInstancesMap = {};
        Promise.all(
            proj.relationSpecs.flatMap(relSpec => [
                relationInstances(relSpec.e1Type, relSpec.label, relSpec.e2Type, projectName),
                relationInstances(
                    `NEG_${relSpec.e1Type}`,
                    `NEG_${relSpec.label}`,
                    `NEG_${relSpec.e2Type}`,
                    projectName
                )
            ])
        )
            .then(res => {
                for (const [i, relInsts] of res.entries()) {
                    const relId =
                        i % 2 === 0
                            ? `${proj.relationSpecs[i / 2].e1Type}-${
                                  proj.relationSpecs[i / 2].label
                              }-${proj.relationSpecs[i / 2].e2Type}`
                            : `NEG_${proj.relationSpecs[Math.floor(i / 2)].e1Type}-NEG_${
                                  proj.relationSpecs[Math.floor(i / 2)].label
                              }-NEG_${proj.relationSpecs[Math.floor(i / 2)].e2Type}`;
                    newRelationInstancesMap[relId] = relInsts;
                }
                console.log('setting relation instances map');
                setRelationInstancesMap(newRelationInstancesMap);
            })
            .finally(() => {
                console.log('turning off spinner');
                setProjectRelationInstancesLoading(false);
            });
    };

    useEffect(() => {
        if (typeof currProject !== 'undefined') {
            loadRelationInstancesMap(currProject);
        }
    }, [updateRelationInstancesMap, currProject]);

    return (
        <div>
            <Layout>
                <AppHeader project={currProject} />

                <Content>
                    <div className="site-layout-content">
                        <Card>
                            <Row>
                                <Col span={24}>
                                    <Tabs defaultActiveKey="1" type="card">
                                        <TabPane tab="General" key="1">
                                            <GeneralInformation
                                                project={
                                                    typeof currProject !== 'undefined'
                                                        ? currProject
                                                        : {
                                                              name: 'NA',
                                                              description: 'NA',
                                                              editors: [],
                                                              contacts: [],
                                                              isPublic: false,
                                                              relationSpecs: []
                                                          }
                                                }
                                                relationInstancesMap={relationInstancesMap}
                                                loading={projectRelationInstancesLoading}
                                            />
                                        </TabPane>
                                        <TabPane tab="Browse" key="2">
                                            <TreeView
                                                project={projectName}
                                                relationInstancesMap={relationInstancesMap}
                                                loading={projectRelationInstancesLoading}
                                            />
                                        </TabPane>
                                        <TabPane tab="Visualize" key="3">
                                            <NetworkTree
                                                project={projectName}
                                                relationInstancesMap={relationInstancesMap}
                                                loading={projectRelationInstancesLoading}
                                            />
                                        </TabPane>
                                        {isProjectEditor ? (
                                            <TabPane tab="Annotate" key="4">
                                                <AnnotatePage
                                                    project={currProject}
                                                    relationInstancesMap={relationInstancesMap}
                                                    setUpdateRelationInstancesMap={
                                                        setUpdateRelationInstancesMap
                                                    }
                                                    loading={projectRelationInstancesLoading}
                                                />
                                            </TabPane>
                                        ) : (
                                            <></>
                                        )}
                                        {isProjectEditor ? (
                                            <TabPane tab="Relation Instances" key="5">
                                                <RelationsTable
                                                    project={currProject}
                                                    relationInstancesMap={relationInstancesMap}
                                                    setUpdateRelationInstancesMap={
                                                        setUpdateRelationInstancesMap
                                                    }
                                                    loading={projectRelationInstancesLoading}
                                                />
                                            </TabPane>
                                        ) : (
                                            <></>
                                        )}
                                        {isProjectEditor ? (
                                            <TabPane tab="Name Unification" key="6">
                                                <NameUnification
                                                    project={projectName}
                                                    relationInstancesMap={relationInstancesMap}
                                                    setUpdateRelationInstancesMap={
                                                        setUpdateRelationInstancesMap
                                                    }
                                                    loading={projectRelationInstancesLoading}
                                                />
                                            </TabPane>
                                        ) : (
                                            <></>
                                        )}
                                    </Tabs>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Content>
                <AppFooter />
            </Layout>
        </div>
    );
};

export default ProjectPage;
