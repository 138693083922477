import React from 'react';
import { Layout, Row, Col } from 'antd';
import bmeLogo from '../icon/BME_logo_t.png';
import ai2Logo from '../icon/ai2_logo.png';
import ercLogo from '../icon/erc_logo.png';
import biuLogo from '../icon/biu.svg';
import technionLogo from '../icon/technion.png';

const { Footer } = Layout;
const AppFooter = () => {
    return (
        <Footer style={{ textAlign: 'center' }}>
            Built by AI2 in collaboration with the Technion BME ©2021{' '}
            <img alt="AI2 Logo" src={ai2Logo} style={{ maxHeight: '40px' }} />
            <img alt="BME Logo" src={bmeLogo} style={{ maxHeight: '40px' }} />
            <img alt="ERC Logo" src={ercLogo} style={{ maxHeight: '40px' }} />
            <img alt="BIU Logo" src={biuLogo} style={{ maxHeight: '40px' }} />
            <img alt="Technion Logo" src={technionLogo} style={{ maxHeight: '40px' }} />
        </Footer>
    );
};

export default AppFooter;
