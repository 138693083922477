import React, { useEffect, useState } from 'react';
import { Button, Col, notification, Row, Select, Space, Table } from 'antd';
import { Reference } from '../bl';
import { CheckOutlined, CloseOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import { Project, RelationInstance, removeRelation } from '../api';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';
import RelationSelection from './RelationSelection';
import { QueryResultsTableData } from './QueryResultsTable';

interface Props {
    project: Project;
    relationInstancesMap: {
        [k: string]: Array<RelationInstance>;
    };
    setUpdateRelationInstancesMap: (updateRelationInstancesMap: string) => void;
    loading: boolean;
}

export interface RelationTableData {
    key: number;
    entity1Type: string;
    entity1: string;
    relationLabel: string;
    entity2Type: string;
    entity2: string;
    reference: Reference;
    label: string;
    annotator: string;
}

function renderExample(text: string) {
    const e1OpenIndex = text.indexOf('<e1>');
    const e1CloseIndex = text.indexOf('</e1>');
    const e2OpenIndex = text.indexOf('<e2>');
    const e2CloseIndex = text.indexOf('</e2>');
    const firstOpen = e1OpenIndex < e2OpenIndex ? e1OpenIndex : e2OpenIndex;
    const firstClose = e1OpenIndex < e2OpenIndex ? e1CloseIndex : e2CloseIndex;
    const secondOpen = e1OpenIndex < e2OpenIndex ? e2OpenIndex : e1OpenIndex;
    const secondClose = e1OpenIndex < e2OpenIndex ? e2CloseIndex : e1CloseIndex;

    return (
        <span>
            {text.substr(0, firstOpen)}
            <b>{text.substr(firstOpen + 4, firstClose - firstOpen - 4)}</b>
            {text.substr(firstClose + 5, secondOpen - firstClose - 5)}
            <b>{text.substr(secondOpen + 4, secondClose - secondOpen - 4)}</b>
            {text.substr(secondClose + 5)}
        </span>
    );
}

const RelationsTable = (props: Props) => {
    const [relationData, setRelationData] = useState<Array<RelationTableData>>([]);
    const [entity1Type, setEntity1Type] = useState<string>('NA');
    const [entity2Type, setEntity2Type] = useState<string>('NA');
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([]);
    const [selectedRows, setSelectedRows] = useState<Array<RelationTableData>>([]);
    const [load, setLoad] = useState<boolean>(false);
    const hasSelected = selectedRowKeys.length > 0;
    const undoAddition = (
        entity1Type: string,
        entity1: string,
        relationLabel: string,
        entity2Type: string,
        entity2: string,
        referenceSentence: string,
        referenceTitle: string,
        referencePMID: string,
        label: string,
        username: string
    ) => {
        const e1Type = label === 'NEGATIVE' ? `NEG_${entity1Type}` : entity1Type;
        const relLabel = label === 'NEGATIVE' ? `NEG_${relationLabel}` : relationLabel;
        const e2Type = label === 'NEGATIVE' ? `NEG_${entity2Type}` : entity2Type;

        if (label === 'POSITIVE') {
        }
        removeRelation(
            e1Type,
            entity1,
            relLabel,
            e2Type,
            entity2,
            referenceSentence,
            referenceTitle,
            referencePMID,
            username,
            props.project.name
        )
            .then(res => props.setUpdateRelationInstancesMap(uuid()))
            .catch(err =>
                notification.open({
                    message: 'Failed to undo relation addition: ' + err
                })
            );
    };

    const relationInstanceColumns = [
        {
            title: entity1Type,
            dataIndex: 'entity1',
            key: 'entity1',
            sorter: (a, b) => a.entity1.localeCompare(b.entity1),
            render: text => <a>{text}</a>
        },
        {
            title: entity2Type,
            dataIndex: 'entity2',
            key: 'entity2',
            sorter: (a, b) => a.entity2.localeCompare(b.entity2),
            render: text => <a>{text}</a>
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
            key: 'reference',
            render: example => (
                <div>
                    <div>
                        <a href={'https://pubmed.ncbi.nlm.nih.gov/' + example.id} target="_blank">
                            {example.title} {example.year ? `(${example.year})` : ''}
                        </a>{' '}
                    </div>
                    <div>{renderExample(example.text)}</div>
                </div>
            )
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
            filters: [
                { text: 'POSITIVE', value: 'POSITIVE' },
                { text: 'NEGATIVE', value: 'NEGATIVE' }
            ],
            defaultFilteredValue: ['POSITIVE'],
            onFilter: (value, record) => record.label.indexOf(value) === 0,
            render: text => text
        },
        {
            title: 'Annotator',
            dataIndex: 'annotator',
            key: 'annotator',
            filters: relationData
                .map(row => row.annotator)
                .filter((val, index, self) => self.indexOf(val) === index)
                .map(val => ({ text: val, value: val })),
            onFilter: (value, record) => record.annotator.indexOf(value) === 0,
            render: text => text
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '8%',
            render: (text, record, index) => (
                <span>
                    <Button
                        onClick={e =>
                            undoAddition(
                                record.entity1Type,
                                record.entity1,
                                record.relationLabel,
                                record.entity2Type,
                                record.entity2,
                                record.reference.text,
                                record.reference.title,
                                record.reference.id,
                                record.label,
                                record.annotator
                            )
                        }>
                        <UndoOutlined />
                    </Button>
                </span>
            )
        }
    ];

    useEffect(() => {
        console.log('setting relation table load');
        setLoad(props.loading);
    }, [props.loading]);
    const toNegRelationSpec = (relationSpecs: string) => {
        const toks = relationSpecs.split('-');
        return `NEG_${toks[0]}-NEG_${toks[1]}-NEG_${toks[2]}`;
    };

    const handleRelationSpecsChagne = (relationSpecs: string) => {
        if (relationSpecs in props.relationInstancesMap) {
            const toks = relationSpecs.split('-');
            const entity1Type = toks[0];
            const relationLabel = toks[1];
            const entity2Type = toks[2];
            setEntity1Type(entity1Type);
            setEntity2Type(entity2Type);
            setRelationData(
                props.relationInstancesMap[relationSpecs]
                    .map((ri, idx) => ({
                        key: idx,
                        entity1Type: entity1Type,
                        entity1: ri.entity1Name,
                        relationLabel: relationLabel,
                        entity2Type: entity2Type,
                        entity2: ri.entity2Name,
                        reference: {
                            text: ri.refSentence,
                            title: ri.refTitle,
                            id: ri.refId,
                            year: ri.refYear
                        },
                        label: 'POSITIVE',
                        annotator: ri.annotator
                    }))
                    .concat(
                        props.relationInstancesMap[toNegRelationSpec(relationSpecs)].map(
                            (ri, idx) => ({
                                key: idx,
                                entity1Type: entity1Type,
                                entity1: ri.entity1Name,
                                relationLabel: relationLabel,
                                entity2Type: entity2Type,
                                entity2: ri.entity2Name,
                                reference: {
                                    text: ri.refSentence,
                                    title: ri.refTitle,
                                    id: ri.refId,
                                    year: ri.refYear
                                },
                                label: 'NEGATIVE',
                                annotator: ri.annotator
                            })
                        )
                    )
            );
        }
    };

    useEffect(() => {
        if (props.project.relationSpecs.length > 0) {
            const rel = props.project.relationSpecs[0];
            const relationStr = `${rel.e1Type}-${rel.label}-${rel.e2Type}`;
            handleRelationSpecsChagne(relationStr);
        }
    }, [props.project, props.relationInstancesMap]);

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        console.log('selectedRow changed: ', selectedRows);
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    const undoSelectionClicked = () => {
        for (const row of selectedRows) {
            undoAddition(
                row.entity1Type,
                row.entity1,
                row.relationLabel,
                row.entity2Type,
                row.entity2,
                row.reference.text,
                row.reference.title,
                row.reference.id,
                row.label,
                row.annotator
            );
        }
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    return (
        <>
            <Row gutter={[24, 24]}>
                <Col span={8}>
                    <RelationSelection
                        project={props.project}
                        handleRelationSpecsChange={handleRelationSpecsChagne}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Space>
                        <Button onClick={undoSelectionClicked} disabled={!hasSelected}>
                            <UndoOutlined />
                        </Button>
                    </Space>
                    <Table
                        rowSelection={rowSelection}
                        loading={load}
                        columns={relationInstanceColumns}
                        dataSource={relationData}
                    />
                </Col>
            </Row>
        </>
    );
};

export default RelationsTable;
