import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Button, Form, Input, message, Modal, Radio } from 'antd';
import { createProject, modifyProject, Project } from '../api';
import { FormOutlined } from '@ant-design/icons';
import { systemNameLengthValidator } from './utils';
const { TextArea } = Input;

interface Props {
    visible;
    onCreate;
    onCancel;
    project?: Project;
    creatingUserEmail?: string;
}

const EditProjectForm = (props: Props) => {
    const [form] = Form.useForm();
    const layout = {
        labelCol: {
            span: 8
        },
        wrapperCol: {
            span: 16
        }
    };
    const modifyExistingProject = typeof props.project !== 'undefined';
    return (
        <Modal
            visible={props.visible}
            title={modifyExistingProject ? 'Modify Project' : 'Create a New Project'}
            okText={modifyExistingProject ? 'Modify' : 'Create'}
            cancelText="Cancel"
            onCancel={props.onCancel}
            onOk={() => {
                form.validateFields()
                    .then(values => {
                        form.resetFields();
                        props.onCreate(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}>
            <Form
                {...layout}
                form={form}
                name="form_in_modal"
                initialValues={{
                    projectName: props.project != null ? props.project.name : '',
                    projectDisplayName: props.project != null ? props.project.displayName : '',
                    projectDesc: props.project != null ? props.project.description : '',
                    projectEditors:
                        props.project != null
                            ? props.project.editors.join(', ')
                            : props.creatingUserEmail,
                    projectContacts:
                        props.project != null
                            ? props.project.contacts.join(', ')
                            : props.creatingUserEmail,
                    projectVisibility:
                        props.project != null
                            ? props.project.isPublic
                                ? 'public'
                                : 'private'
                            : 'public'
                }}>
                <Form.Item
                    name="projectName"
                    label="System Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the name of the project'
                        },
                        {
                            validator: systemNameLengthValidator
                        }
                    ]}>
                    <Input disabled={typeof props.project !== 'undefined'} />
                </Form.Item>
                <Form.Item
                    name="projectDisplayName"
                    label="Display Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the display name of the project'
                        }
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="projectDesc"
                    label="Project Description"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the project description.'
                        }
                    ]}>
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item
                    name="projectEditors"
                    label="Editors"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the name of the project'
                        }
                    ]}>
                    <Input placeholder="list of emails, comma separated" />
                </Form.Item>
                <Form.Item
                    name="projectContacts"
                    label="Contacts"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the name of the project'
                        }
                    ]}>
                    <Input placeholder="list of emails, comma separated" />
                </Form.Item>
                <Form.Item
                    name="projectVisibility"
                    label="Visibility"
                    rules={[
                        {
                            required: true,
                            message: 'Select whether the knowledge graph should be made public'
                        }
                    ]}>
                    <Radio.Group>
                        <Radio value="public">Public</Radio>
                        <br />
                        <Radio value="private">Private</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};

interface EditProjectDialogProps {
    project?: Project;
    creatingUserEmail?: string;
    disabled: boolean;
    buttonLabel: string;
}

const EditProjectDialog = (props: EditProjectDialogProps) => {
    const [visible, setVisible] = useState(false);

    const onCreate = values => {
        console.log('Received values of form: ', values);
        const projectOp = typeof props.project !== 'undefined' ? modifyProject : createProject;
        projectOp(
            values.projectName,
            values.projectDisplayName,
            values.projectDesc,
            values.projectEditors.split(',').map(s => s.trim()),
            values.projectContacts.split(',').map(s => s.trim()),
            values.projectVisibility === 'public'
        )
            .then(res => {
                message.success('Operation completed successfully');
                window.location.href = `/projects/${values.projectName}`;
            })
            .catch(e => {
                if (e.response) {
                    message.error(e.response.data.detail);
                } else {
                    message.error(`Operation failed: ${e.message}`);
                }
            });
        setVisible(false);
    };

    return (
        <>
            <Button
                disabled={props.disabled}
                onClick={() => {
                    setVisible(true);
                }}>
                <FormOutlined />
                {props.buttonLabel}
            </Button>
            <EditProjectForm
                visible={visible}
                onCreate={onCreate}
                onCancel={() => {
                    setVisible(false);
                }}
                project={props.project}
                creatingUserEmail={props.creatingUserEmail}
            />
        </>
    );
};

export default EditProjectDialog;
