import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const LoginRedirect = () => {
    const { handleRedirectCallback } = useAuth0();
    const [redirectURL, setRedirectURL] = useState<string>('');
    useEffect(() => {
        console.log(window.location.href);
        console.log(window.location.search);
        const initAuth0 = async () => {
            console.log(window.location.search);
            if (window.location.search.includes('code=')) {
                const res = await handleRedirectCallback();
                console.log(res);
                console.log(res.appState.target);
                setRedirectURL(res.appState.target);
            }
        };
        initAuth0();
    }, []);
    console.log(`redirect URL: ${redirectURL}`);
    return redirectURL === '' ? (
        <>
            <div>{'Redirecting...'}</div>
        </>
    ) : (
        <Redirect to={redirectURL} />
    );
};

export default LoginRedirect;
