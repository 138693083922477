import React, { Dispatch, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Modal, Button, Row, Col, Input, AutoComplete, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons/lib';
import { addRelation, entityForType } from '../api';
import { render } from 'react-dom';
import { renderExample } from './QueryResultsTable';
import { Reference } from '../bl';
import { v4 as uuid } from 'uuid';
import { useParams } from 'react-router-dom';

interface Props {
    entity1Type: string;
    entity1: string;
    relationLabel: string;
    entity2Type: string;
    entity2: string;
    reference: Reference;
    setUpdateRelationInstancesMap: (updateRelationInstancesMap: string) => void;
    username: string;
}
const { Option } = AutoComplete;
interface DatasetOption {
    value: string;
    label: string;
}

const AddRelationsDialog = (props: Props) => {
    const projectName = useParams<{ projectName: string }>().projectName;
    const [visible, setVisible] = useState<boolean>(false);
    const [entity1Name, setEntity1Name] = useState<string>(props.entity1);
    const [entity2Name, setEntity2Name] = useState<string>(props.entity2);
    const [entity1Links, setEntity1Links] = useState<Set<string>>(new Set());
    const [entity2Links, setEntity2Links] = useState<Set<string>>(new Set());

    const showModal = () => {
        if (props.username === '') {
            notification.open({
                message: 'To annotate data you must set your username in the Settings dialog.',
                description: 'To annotate data you must set your username in the Settings dialog.'
            });
            return;
        }
        setVisible(true);
    };

    const handleOk = e => {
        addRelation(
            props.entity1Type,
            entity1Name,
            entity1Name,
            props.relationLabel,
            props.entity2Type,
            entity2Name,
            entity2Name,
            props.reference.text,
            props.reference.title,
            props.reference.id,
            props.reference.year,
            props.username,
            projectName
        )
            .then(res => {
                props.setUpdateRelationInstancesMap(uuid());
            })
            .catch(err =>
                notification.open({
                    message: 'Failed adding the relation: ' + err
                })
            );
        setVisible(false);
    };

    const handleCancel = e => {
        console.log(props);
        setVisible(false);
    };

    const handleChange = value => {};

    const onEntity1NameChange = value => {
        setEntity1Name(value);
    };

    const onEntity2NameChange = value => {
        setEntity2Name(value);
    };

    useEffect(() => {
        entityForType(props.entity1Type, projectName).then(res => setEntity1Links(res));
        entityForType(props.entity2Type, projectName).then(res => setEntity2Links(res));
    }, [visible]);

    return (
        <>
            <Button onClick={showModal}>
                <EditOutlined />
            </Button>
            <Modal title="Add" visible={visible} onOk={handleOk} onCancel={handleCancel}>
                <Row>
                    <Col span={4}>{'example'}</Col>
                    <Col span={20}>{renderExample(props.reference)}</Col>
                </Row>
                <Row>
                    <Col span={6}>{`${props.entity1Type}:`}</Col>
                    <Col span={10}>
                        <AutoComplete
                            style={{
                                width: '100%'
                            }}
                            value={entity1Name}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onChange={onEntity1NameChange}>
                            {[...entity1Links].map((l, idx) => (
                                <Option key={idx} value={l}>
                                    {l}
                                </Option>
                            ))}
                        </AutoComplete>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>{`${props.entity2Type}:`}</Col>
                    <Col span={10}>
                        <AutoComplete
                            style={{
                                width: '100%'
                            }}
                            value={entity2Name}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onChange={onEntity2NameChange}>
                            {[...entity2Links].map((l, idx) => (
                                <Option key={idx} value={l}>
                                    {l}
                                </Option>
                            ))}
                        </AutoComplete>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default AddRelationsDialog;
