import React, { useEffect, useState } from 'react';
import { Project, RelationSpecs } from '../api';
import { Select } from 'antd';
const { Option } = Select;
interface RelationSelectionProps {
    project: Project;
    handleRelationSpecsChange: (relationSpecs: string) => void;
}

const RelationSelection = (props: RelationSelectionProps) => {
    const [selectedRelationSpecs, setSelectedRelationSpecs] = useState<RelationSpecs>();
    useEffect(() => {
        if (props.project.relationSpecs.length > 0) {
            setSelectedRelationSpecs(props.project.relationSpecs[0]);
        }
    }, [props.project]);

    const handleRelationSpecsChange = (relationSpecs: string) => {
        const toks = relationSpecs.split('-');
        const relSpecs = { e1Type: toks[0], label: toks[1], e2Type: toks[2] };
        setSelectedRelationSpecs(relSpecs);
        props.handleRelationSpecsChange(relationSpecs);
    };
    return (
        <>
            {'Select a relation: '}
            <Select
                value={
                    typeof selectedRelationSpecs !== 'undefined'
                        ? `${selectedRelationSpecs.e1Type}-${selectedRelationSpecs.label}-${selectedRelationSpecs.e2Type}`
                        : ''
                }
                onChange={handleRelationSpecsChange}>
                {props.project.relationSpecs.map(rs => (
                    <Option value={`${rs.e1Type}-${rs.label}-${rs.e2Type}`}>
                        {`${rs.e1Type}-${rs.label}-${rs.e2Type}`}
                    </Option>
                ))}
            </Select>
        </>
    );
};

export default RelationSelection;
