import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Button, Modal } from 'antd';

const CopyQueryHelp = () => {
    const [visible, setVisible] = useState(false);

    const show = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <>
            <Button type="primary" onClick={show}>
                How to Add?
            </Button>

            <Modal
                title="Adding SPIKE Queries"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}>
                <p>
                    To add a query to SPIKE-KBC you should first craft a query in{' '}
                    <a target="_blank" href="https://spike.apps.allenai.org">
                        SPIKE
                    </a>{' '}
                    which contains captures named arg1 and arg2 (see example{' '}
                    <a
                        target="_blank"
                        href="https://spike.apps.allenai.org/datasets/pubmed/search#query=eyJtYWluIjoie1widHlwZVwiOlwiU1wiLFwiY29udGVudFwiOlwiPD5hcmcxOnNvbWV0aGluZyBpcyBhICRyaXNrICRmYWN0b3IgZm9yIGFyZzI6W3ddc3Ryb2tlXCJ9IiwiZmlsdGVycyI6IltdIiwiY2FzZVN0cmF0ZWd5IjoiaWdub3JlIn0=&autoRun=true">
                        here
                    </a>
                    ).
                </p>
                <p>
                    You should then enable the lab feature allowing copying queries as JSON strings,
                    as in the image below:
                    <div>
                        <img
                            src={process.env.PUBLIC_URL + '/images/copyJsonLabOption.png'}
                            width="100%"
                            height="100%"
                        />
                    </div>
                </p>
                <p>
                    And then press the copy as JSON button, as in the image below:
                    <img
                        src={process.env.PUBLIC_URL + '/images/copyJsonButton.png'}
                        width="100%"
                        height="100%"
                    />
                </p>
                <p>
                    This will copy a string representation of the query to the clipboard. You should
                    then paste the string into the "Paste SPIKE query" box to add it.
                </p>
            </Modal>
        </>
    );
};

export default CopyQueryHelp;
