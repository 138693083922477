import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import { Table, Input, InputNumber, Popconfirm, Form, Typography } from 'antd';
import { RelationInstance, renameEntity } from '../api';
import { v4 as uuid } from 'uuid';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`
                        }
                    ]}>
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const NameUnification = (props: {
    relationInstancesMap: {
        [k: string]: Array<RelationInstance>;
    };
    project: string;
    setUpdateRelationInstancesMap: (uuid: string) => void;
    loading: boolean;
}) => {
    const [form] = Form.useForm();

    const [editingKey, setEditingKey] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        console.log('rel instance map has changed');
        const allRelInsts: Array<RelationInstance> = Object.values(
            props.relationInstancesMap
        ).flatMap(arr => arr);
        const namesByTypeVal: { [entityType: string]: Set<string> } = allRelInsts
            .flatMap(ri => [
                [ri.entity1Type, ri.entity1Name],
                [ri.entity2Type, ri.entity2Name]
            ])
            .reduce((accumulator, item) => {
                if (!(item[0] in accumulator)) {
                    accumulator[item[0]] = new Set<string>();
                }
                accumulator[item[0]].add(item[1]);
                return accumulator;
            }, {});
        setData(
            Object.entries(namesByTypeVal).flatMap(([type, entities]) =>
                Array.from(entities)
                    .map(e => ({ key: `${type}/${e}`, name: e, type: type }))
                    .sort((a, b) =>
                        a.type.localeCompare(b.type) !== 0
                            ? a.type.localeCompare(b.type)
                            : a.name.localeCompare(b.name)
                    )
            )
        );
    }, [props.relationInstancesMap]);

    const isEditing = record => record.key === editingKey;

    const edit = record => {
        form.setFieldsValue({
            name: '',
            age: '',
            address: '',
            ...record
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key, project) => {
        try {
            const row = (await form.validateFields()) as { key: string; name: string };
            const newData = [...data];
            const index = newData.findIndex(item => key === item.key);

            if (index > -1) {
                const item = newData[index];
                const res = await renameEntity(item.type, item.name, row.name, project);
                console.assert(
                    res.data === 'renamed' || res.data === 'merged',
                    `Unexpcted return to renameEntity: ${res}`
                );
                if (res.data === 'renamed') {
                    console.log('renamed');
                    newData.splice(index, 1, { ...item, ...row });
                } else if (res.data === 'merged') {
                    console.log('merged');
                    newData.splice(index, 1);
                }
                console.log('refreshing relation instances map...');
                props.setUpdateRelationInstancesMap(uuid());
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: 'type',
            dataIndex: 'type',
            width: '25%',
            filters: data
                .map(row => row.type)
                .filter((val, index, self) => self.indexOf(val) === index)
                .map(val => ({ text: val, value: val })),
            onFilter: (value, record) => record.type.indexOf(value) === 0,
            editable: false
        },
        {
            title: 'name',
            dataIndex: 'name',
            width: '25%',
            editable: true
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a
                            href="javascript:;"
                            onClick={() => save(record.key, props.project)}
                            style={{
                                marginRight: 8
                            }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                );
            }
        }
    ];
    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });
    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell
                    }
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 50
                }}
            />
        </Form>
    );
};

export default NameUnification;
