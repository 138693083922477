import { useD3 } from '../hooks/useD3';
import { Button, Card, Col, Drawer, Empty, List, Popover, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import { HierarchyNode, HierarchyPointNode, TreeLayout } from 'd3-hierarchy';
import { chainPairwiseCounts, RelationInstance, relationInstances } from '../api';
import { filterInPlace, groupBy, pairwise } from './utils';
import { Reference } from '../bl';
import { renderExampleText } from './QueryResultsTable';
import { DraggableAttribute } from './ReactPivotTableUI';
import './NetworkTree.css';
import 'react-pivottable/pivottable.css';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

interface TreeNode {
    name: string;
    type: string;
    children?: this[];
    parent?: this;
    references?: Reference[];
}

const ancestors = (node: TreeNode) => {
    const ancestors = [];
    while (typeof node.parent !== 'undefined' && node.name !== 'root') {
        ancestors.push(node.name);
        node = node.parent;
    }
    return ancestors.reverse();
};

// removes a node if it doesn't have children, and then its parents up to the first node that has children.
const removeChildlessBranch = (treeNode: TreeNode) => {
    if ('parent' in treeNode && treeNode.children.length === 0) {
        const index = treeNode.parent.children.indexOf(treeNode);
        if (index > -1) {
            treeNode.parent.children.splice(index, 1);
            removeChildlessBranch(treeNode.parent);
        } else {
            throw new Error(`couldn't remove node ${treeNode.name}`);
        }
    }
};

const removeTreeNode = (treeNode: TreeNode) => {
    if ('parent' in treeNode) {
        const index = treeNode.parent.children.indexOf(treeNode);
        if (index > -1) {
            treeNode.parent.children.splice(index, 1);
            removeChildlessBranch(treeNode.parent);
        } else {
            throw new Error(`couldn't remove node ${treeNode.name}`);
        }
    }
};

const ROOT_NODE_NAME = 'root';
const ROOT_NODE_TYPE = 'root';
const MORE_NODE_NAME = 'more';
const MORE_NODE_TYPE = 'MoreNode';

function NetworkTree(props: {
    relationInstancesMap: {
        [k: string]: Array<RelationInstance>;
    };
    project: string;
    loading: boolean;
}) {
    const [addTypeVisible, setAddTypeVisible] = useState<boolean>(false);
    const [currReference, setCurrReference] = useState<Reference[]>(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [rankingInProgress, setRankingInProgress] = useState<boolean>(false);
    const [data, setData] = useState<TreeNode>({
        name: ROOT_NODE_NAME,
        type: ROOT_NODE_TYPE,
        children: []
    });
    const [fullyExpandedNodes, setFullyExpandedNodes] = useState<Set<string>>(
        new Set([`${ROOT_NODE_NAME}`])
    );
    const [valuesFilter, setValuesFilter] = useState<{}>({});
    const [attrValues, setAttrValues] = useState<{}>({});
    const [typeChain, setTypeChain] = useState<Array<string>>([]);
    const entityTypes = Object.keys(props.relationInstancesMap)
        .filter(relId => !relId.startsWith('NEG_'))
        .map(relId => [relId.split('-')[0], relId.split('-')[2]])
        .flatMap(arr => arr)
        .filter((v, i, a) => a.indexOf(v) === i);
    const [selectableEntityTypes, setSelectableEntityTypes] = useState<Set<string>>(
        new Set(entityTypes)
    );
    // todo: we (falsely) assume that the entity types are enough to determine the relation, we should stop doing this
    //       and instead modify the pairwise body to use explicit relation names.
    const typeTupleToRelInstances = {};
    for (const [relId, relInsts] of Object.entries(props.relationInstancesMap)) {
        const relToks = relId.split('-');
        const newRelId = `${relToks[0]}-${relToks[2]}`;
        typeTupleToRelInstances[newRelId] = props.relationInstancesMap[relId];
    }

    const pathToRootRecursive = (node: TreeNode, path: Array<string>): Array<string> => {
        if (node.type !== ROOT_NODE_TYPE) {
            return pathToRootRecursive(node.parent, [node.name, ...path]);
        } else {
            return [ROOT_NODE_NAME, ...path];
        }
    };

    const nodeUniqueKey = (node: TreeNode): string => {
        return pathToRootRecursive(node, []).join('***');
    };

    const limitNumberOfChildren = (node: TreeNode, maxChildren: number): TreeNode => {
        if (!fullyExpandedNodes.has(nodeUniqueKey(node)) && node.children.length > maxChildren) {
            const moreNode = {
                name: MORE_NODE_NAME,
                type: MORE_NODE_TYPE,
                children: [],
                parent: node,
                references: []
            };
            node.children = [...node.children.slice(0, maxChildren), moreNode].map(n =>
                limitNumberOfChildren(n, maxChildren)
            );
        } else {
            console.log(node);
            console.log(nodeUniqueKey(node));
            node.children = node.children.map(n => limitNumberOfChildren(n, maxChildren));
        }
        return node;
    };

    const buildTreeFromTypechain = () => {
        const allRelInsts: Array<RelationInstance> = Object.values(props.relationInstancesMap)
            .flatMap(arr => arr)
            .filter(ri => !ri.relationLabel.startsWith('NEG_'));
        const namesByTypeVal: { [entityType: string]: Set<string> } = allRelInsts
            .flatMap(ri => [
                [ri.entity1Type, ri.entity1Name],
                [ri.entity2Type, ri.entity2Name]
            ])
            .reduce((accumulator, item) => {
                if (!(item[0] in accumulator)) {
                    accumulator[item[0]] = new Set<string>();
                }
                accumulator[item[0]].add(item[1]);
                return accumulator;
            }, {});

        const attrValuesVal = Object.fromEntries(
            Object.entries(namesByTypeVal).map(([entityType, items], i) => [
                entityType,
                Object.assign({}, ...Array.from(items, item => ({ [item]: 1 })))
            ])
        );
        setAttrValues(attrValuesVal);

        const rootNode = {
            name: 'root',
            type: 'root',
            children: []
        };

        let prevLayer: Array<TreeNode> = rootNode.children;
        if (typeChain.length > 0 && Object.keys(props.relationInstancesMap).length > 0) {
            const firstLayerNames = Array.from(namesByTypeVal[typeChain[0]])
                .filter(n => !(typeChain[0] in valuesFilter) || !(n in valuesFilter[typeChain[0]]))
                .sort((a, b) => a.localeCompare(b));
            rootNode.children = firstLayerNames.map(name => ({
                name: name.trim(),
                type: typeChain[0],
                children: []
            }));
            rootNode.children = rootNode.children.map(node => ({ ...node, parent: rootNode }));
            prevLayer = [...rootNode.children];
        }

        pairwise(typeChain, (currEntityType, nextEntityType) => {
            const relName = `${currEntityType}-${nextEntityType}`;
            const reversedRelName = `${nextEntityType}-${currEntityType}`;
            const reverseMatch = !(relName in typeTupleToRelInstances);
            const insts = reverseMatch
                ? typeTupleToRelInstances[reversedRelName]
                : typeTupleToRelInstances[relName];

            const groupedInsts: Record<string, Array<RelationInstance>> = reverseMatch
                ? groupBy(insts, i => i.entity2Name)
                : groupBy(insts, i => i.entity1Name);
            const newPrevLayer: Array<TreeNode> = [];
            for (const treeNode of prevLayer) {
                if (treeNode.name in groupedInsts) {
                    const nodeChildrenWithDups = groupedInsts[treeNode.name].map(ri => ({
                        name: reverseMatch ? ri.entity1Name.trim() : ri.entity2Name.trim(),
                        type: nextEntityType,
                        children: [],
                        parent: treeNode,
                        references: [{ text: ri.refSentence, title: ri.refTitle, id: ri.refId }]
                    }));
                    const nodeChildren = Object.values(groupBy(nodeChildrenWithDups, n => n.name))
                        .map(childInsts =>
                            childInsts.reduce((finalObj, currObj) => {
                                finalObj.references.push(currObj.references[0]);
                                return finalObj;
                            })
                        )
                        .filter(
                            childInsts =>
                                !(nextEntityType in valuesFilter) ||
                                !(childInsts.name in valuesFilter[nextEntityType])
                        )
                        .sort((a, b) => a.name.localeCompare(b.name));
                    if (nodeChildren.length > 0) {
                        // @ts-ignore
                        treeNode.children = nodeChildren;
                        newPrevLayer.push(...nodeChildren);
                    } else {
                        // if not connected to the next layer the element from the tree
                        removeTreeNode(treeNode);
                    }
                } else {
                    // if not connected to the next layer the element from the tree
                    removeTreeNode(treeNode);
                }
            }
            prevLayer = newPrevLayer;
        });
        return rootNode;
    };

    useEffect(() => {
        const typeChainTypes = new Set(typeChain);
        if (typeChain.length === 0) {
            setSelectableEntityTypes(new Set(entityTypes));
        }
        if (typeChain.length > 0 && Object.keys(props.relationInstancesMap).length > 0) {
            const lastLayerType = typeChain[typeChain.length - 1];
            const selectableTypes = new Set<string>();
            for (const relId of Object.keys(props.relationInstancesMap)) {
                const relIdToks = relId.split('-');
                const entity1Type = relIdToks[0];
                const entity2Type = relIdToks[2];
                if (lastLayerType === entity1Type && !typeChainTypes.has(entity2Type)) {
                    selectableTypes.add(entity2Type);
                } else if (lastLayerType === entity2Type && !typeChainTypes.has(entity1Type)) {
                    selectableTypes.add(entity1Type);
                }
            }
            setSelectableEntityTypes(selectableTypes);
        }

        const rootNode = buildTreeFromTypechain();

        setData(limitNumberOfChildren(rootNode, 6));
    }, [typeChain, props.relationInstancesMap, valuesFilter, fullyExpandedNodes]);
    const ref = useD3(
        svg => {
            const width = 954;
            const b = {
                w: 150,
                h: 30,
                s: 3,
                t: 10
            };

            // const width = 600;

            function elbow(d, i) {
                return 'M' + d.source.y + ',' + d.source.x + 'V' + d.target.x + 'H' + d.target.y;
            }

            function initializeBreadcrumbTrail() {
                // Add the svg area.
                const trail = svg
                    .append('g')
                    .attr('width', width)
                    .attr('height', 50)
                    .attr('id', 'trail');
                // Add the label at the end, for the percentage.
                trail
                    .append('text')
                    .attr('id', 'endlabel')
                    .style('fill', '#000');

                // Make the breadcrumb trail visible, if it's hidden.
                d3.select('#trail').style('visibility', '');
            }

            // Generate a string that describes the points of a breadcrumb polygon.
            function breadcrumbPoints(d, i) {
                var points = [];
                points.push('0,0');
                points.push(b.w + ',0');
                points.push(b.w + b.t + ',' + b.h / 2);
                points.push(b.w + ',' + b.h);
                points.push('0,' + b.h);
                if (i > 0) {
                    // Leftmost breadcrumb; don't include 6th vertex.
                    points.push(b.t + ',' + b.h / 2);
                }
                return points.join(' ');
            }
            const color = d3.scaleOrdinal(d3.schemeCategory10).domain(entityTypes);
            // Update the breadcrumb trail to show the current sequence and percentage.
            function updateBreadcrumbs(nodeArray: Array<any>) {
                // Data join; key function combines name and depth (= position in sequence).
                const trail = d3
                    .select('#trail')
                    .selectAll('g')
                    .data(nodeArray, function(d: any) {
                        return d.name;
                    });

                // Remove exiting nodes.
                // trail.exit().remove();

                // Add breadcrumb and label for entering nodes.
                const entering = trail.enter().append('g');

                entering
                    .append('polygon')
                    .attr('points', breadcrumbPoints)
                    .style('fill', function(d) {
                        return color(d.name);
                    });

                entering
                    .append('text')
                    .attr('x', (b.w + b.t) / 2)
                    .attr('y', b.h / 2)
                    .attr('dy', '0.35em')
                    .attr('text-anchor', 'middle')
                    .text(function(d) {
                        return d.name;
                    });

                // Merge enter and update selections; set position for all nodes.
                entering.attr('transform', function(d, i) {
                    return 'translate(' + i * (b.w + b.s) + ', 0)';
                });

                // Now move and update the percentage at the end.
                // d3.select('#trail')
                //     .select('#endlabel')
                //     .attr('x', (nodeArray.length + 0.5) * (b.w + b.s))
                //     .attr('y', b.h / 2)
                //     .attr('dy', '0.35em')
                //     .attr('text-anchor', 'middle')
                //     .text(percentageString);
            }

            svg.selectAll('*').remove();

            const hierarchy: HierarchyNode<TreeNode> = d3.hierarchy(data);
            const rootDeltaX = 10;
            const rootDeltaY = width / (hierarchy.height + 1);
            const root: HierarchyPointNode<TreeNode> = d3
                .tree<TreeNode>()
                .nodeSize([rootDeltaX, rootDeltaY])(hierarchy);

            // at this point, the tree is vertical, so x1-x0 is actually the (future) height of the image.
            let x0 = Infinity;
            let x1 = -x0;
            root.each(d => {
                if (d.x > x1) x1 = d.x;
                if (d.x < x0) x0 = d.x;
            });

            // We're going to remove the root from the image, so we want to know the distance from root
            // to its closest neighbour so we can move the graph to the left by that distance.
            let rootToChildDelta = 0;
            if (root.children !== undefined) {
                for (const child of root.children) {
                    // todo: we need to account the distance between root and child doesn't account for the child
                    //       node name, so I correct for it guessing that each character is 15 px (which is not always accurate).
                    const delta = child.y - root.y;
                    if (delta > rootToChildDelta) {
                        rootToChildDelta = delta;
                    }
                }
                const maxChildCharLen = Math.max(
                    ...root.children.map(child =>
                        child.children && child.children.length > 0 ? child.data.name.length : 0
                    )
                );
                rootToChildDelta -= maxChildCharLen * 5;
            }

            svg.attr('viewBox', [0, 0, width, x1 - x0 + rootDeltaX * 2 + b.h + 10]);
            initializeBreadcrumbTrail();
            updateBreadcrumbs(
                typeChain.map(t => ({
                    name: t
                }))
            );

            const g = svg
                .append('g')
                .attr('font-family', 'sans-serif')
                .attr('font-size', 10)
                .attr(
                    'transform',
                    `translate(${-rootToChildDelta + 10},${rootDeltaX - x0 + b.h + 10})`
                );

            const link = g
                .append('g')
                .attr('fill', 'none')
                .attr('stroke', '#555')
                .attr('stroke-opacity', 0.4)
                .attr('stroke-width', 1.5)
                .selectAll('path')
                .data(root.links())
                .join('path')
                .filter(d => {
                    return d.source !== root;
                })
                .on('mouseover', function(e, d) {
                    d3.select(this).attr('stroke-width', 3);
                })
                .on('mouseout', function(e, d) {
                    d3.select(this).attr('stroke-width', 1.5);
                })
                .on('click', (e, d) => {
                    setCurrReference(d.target.data.references);
                    setDrawerVisible(true);
                })
                .attr('class', 'link')
                .attr(
                    'd',
                    d3
                        .linkHorizontal<any, HierarchyPointNode<TreeNode>>()
                        .x(d => d.y)
                        .y(d => d.x)
                );
            // .attr('d', elbow)

            const node = g
                .append('g')
                .attr('stroke-linejoin', 'round')
                .attr('stroke-width', 3)
                .selectAll('g')
                .data(root.descendants())
                .join('g')
                .filter(d => d !== root)
                .attr('transform', d => `translate(${d.y},${d.x})`);

            var nodeTooltip = d3
                .select('#svgcontiner')
                .append('div')
                .attr('class', 'tooltipdiv')
                .style('position', 'absolute')
                .style('visibility', 'hidden')
                .style('background-color', 'white')
                .style('border', 'solid')
                .style('border-width', '1px')
                .style('border-radius', '5px')
                .style('padding', '10px')
                .style('max-width', '300px');

            node.append('circle')
                .attr('fill', function(d) {
                    return color(d.data.type);
                })
                .attr('r', 4)
                .on('mouseover', function(e, d) {
                    if (d.data.type === MORE_NODE_TYPE) {
                        return;
                    }
                    // todo: this code is specific to the csdd dataset, we should generalize it.
                    const ancestorsList = ancestors(d.data);
                    let biomaterial = 'a biomaterial';
                    let drug = 'drugs';
                    let cell = 'certain cell types';
                    let cancer = 'various cancers';
                    let target = 'a molecular target';
                    let ligand = 'a targeting ligand';

                    for (const [index, value] of ancestorsList.entries()) {
                        const type = typeChain[index];
                        if (type === 'Biomaterial') {
                            biomaterial = value;
                        } else if (type === 'Cancer') {
                            cancer = value;
                        } else if (type === 'Ligand') {
                            ligand = value;
                        } else if (type === 'Target') {
                            target = value;
                        } else if (type === 'celltype') {
                            cell = value;
                        } else if (type === 'Drug') {
                            drug = value;
                        }
                    }
                    const hypothesisStr = `<b>${biomaterial}</b> can carry <b>${drug}</b> to <b>${cancer}</b> by actively binding <b>${target}</b> expressed by <b>${cell}</b> using <b>${ligand}</b>.`;
                    const pubmedSearchStr = ancestorsList.map(t => `(${t})`).join(' AND ');
                    const pubmedUrl = `https://pubmed.ncbi.nlm.nih.gov/?term=${encodeURIComponent(
                        pubmedSearchStr
                    )}`;
                    const entitiesHtml = `<h5>Entities</h5><p>${ancestorsList
                        .map(a => `<b>${a}</b>`)
                        .join(', ')}</p>`;
                    let hypothesisHtml = '';
                    if (
                        props.project === 'tddcopy' ||
                        props.project === 'drugdelivery' ||
                        props.project === 'csdd'
                    ) {
                        hypothesisHtml = `<h5>Hypothesis</h5><p>${hypothesisStr}</p>`;
                    }
                    return nodeTooltip
                        .html(
                            `<div>${hypothesisHtml}${entitiesHtml}<a style="font-size: 110%" href="${pubmedUrl}" target="_blank">Explore in PubMed</a></div>`
                        )
                        .transition()
                        .duration(200)
                        .style('visibility', 'visible')
                        .style('top', e.pageY - 150 + 'px')
                        .style('left', e.pageX - 50 + 'px');
                })
                .on('click', (e, d) => {
                    if (d.data.type === MORE_NODE_TYPE) {
                        setFullyExpandedNodes(
                            new Set([...fullyExpandedNodes, nodeUniqueKey(d.data.parent)])
                        );
                    }
                });

            d3.select('#svgcontiner').on('click', function(e) {
                d3.selectAll('.tooltipdiv').style('visibility', 'hidden');
            });

            node.append('text')
                .attr('dy', '0.31em')
                .attr('x', d => (d.children ? -6 : 6))
                .attr('text-anchor', d => (d.children ? 'end' : 'start'))
                .text(d => d.data.name)
                .clone(true)
                .lower()
                .attr('stroke', 'white');
        },
        [data]
    );

    const onClick = (entityType: string) => {
        if (entityType === 'Back') {
            setTypeChain([...typeChain.slice(0, -1)]);
        } else if (!typeChain.includes(entityType)) {
            setTypeChain([...typeChain, entityType]);
        }
    };

    const setValuesInFilter = (attribute, values) => {
        setValuesFilter({
            ...valuesFilter,
            ...{
                [attribute]: values.reduce((r, v) => {
                    r[v] = true;
                    return r;
                }, {})
            }
        });
    };

    function* preorder(root: TreeNode, path: Array<TreeNode>) {
        if (typeof root !== 'undefined') {
            path.push(root);

            // If we're at a leaf, yield the path.
            if (root.children.length === 0) {
                yield path;
            }
            for (const node of root.children) {
                yield* preorder(node, [...path]);
            }
        }
    }

    const addValuesToFilter = (attribute, values) => {
        if (attribute in valuesFilter) {
            setValuesFilter({
                ...valuesFilter,
                [attribute]: {
                    ...valuesFilter[attribute],
                    ...values.reduce((r, v) => {
                        r[v] = true;
                        return r;
                    }, {})
                }
            });
        } else {
            setValuesInFilter(attribute, values);
        }
    };

    const removeValuesFromFilter = (attribute, values) => {
        if (attribute in valuesFilter) {
            setValuesFilter({
                ...valuesFilter,
                [attribute]: Object.keys(valuesFilter[attribute]).reduce((object, key) => {
                    if (!values.includes(key)) {
                        object[key] = valuesFilter[attribute][key];
                    }
                    return object;
                }, {})
            });
        }
    };

    // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
    function exportToCsv(filename, rows) {
        var processRow = function(row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                }
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
                if (j > 0) finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }

        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement('a');
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    const downloadDataAsCSVWithScores = async () => {
        setRankingInProgress(true);
        const fullTree = buildTreeFromTypechain(); // we want the full tree, without "more" nodes.
        const rows = [];
        let headerAdded = false;
        const pairwiseCounts = await chainPairwiseCounts(props.project, typeChain);
        const sumReducer = (accumulator, curr) => accumulator + curr;
        const multReducer = (accumulator, curr) => accumulator * curr;
        const totalCounts = pairwiseCounts.map(_ => 0);
        const totalCountsKeys = totalCounts.map(_ => new Set());
        for (const path of preorder(fullTree, [])) {
            const pathWithoutRoot = path.slice(1);
            if (!headerAdded) {
                const headerRow = [];

                for (const node of pathWithoutRoot) {
                    headerRow.push(node.type);
                }

                for (let i = 0; i < pathWithoutRoot.length - 1; ++i) {
                    headerRow.push(
                        ...[
                            `${pathWithoutRoot[i].type}-${pathWithoutRoot[i + 1].type} Count`,
                            `${pathWithoutRoot[i].type}-${pathWithoutRoot[i + 1].type} Total`,
                            `${pathWithoutRoot[i].type}-${pathWithoutRoot[i + 1].type} Norm`
                        ]
                    );
                }
                headerRow.push('SUM Score');
                headerRow.push('MULT Score');

                headerAdded = true;

                rows.push(headerRow);
            }
            const row: Array<string> = [];
            for (const node of pathWithoutRoot) {
                row.push(node.name);
            }
            const scores = [];

            for (let i = 0; i < row.length - 1; i++) {
                const key = [row[i].toLowerCase(), row[i + 1].toLowerCase()].join('***');
                if (pairwiseCounts[i].has(key)) {
                    scores.push(pairwiseCounts[i].get(key));
                    scores.push(0); // placeholder for computed total
                    scores.push(0); // placeholder for computed norm
                    if (!totalCountsKeys[i].has(key)) {
                        totalCounts[i] += pairwiseCounts[i].get(key);
                        totalCountsKeys[i].add(key);
                    }
                } else {
                    scores.push(...[0, 0, 0]);
                }
            }
            rows.push(row.concat(scores));
        }
        for (const [idx, row] of rows.entries()) {
            if (idx === 0) {
                continue;
            }
            const normScores = [];
            for (let i = totalCounts.length; i > 0; --i) {
                const pairwiseCount = row[row.length - i * 3];
                const totalCount = totalCounts[totalCounts.length - i];
                const normScore = pairwiseCount / totalCount;
                row[row.length - i * 3 + 1] = totalCount;
                row[row.length - i * 3 + 2] = normScore;
                normScores.push(normScore);
            }
            row.push(normScores.reduce(sumReducer));
            row.push(normScores.reduce(multReducer));
        }

        setRankingInProgress(false);
        exportToCsv(`${props.project}-viz.csv`, rows);
    };

    const downloadDataAsCSV = () => {
        const rows = [];
        let headerAdded = false;
        const fullTree = buildTreeFromTypechain(); // we want the full tree, without "more" nodes.
        for (const path of preorder(fullTree, [])) {
            const pathWithoutRoot = path.slice(1);
            if (!headerAdded) {
                const headerRow = [];
                for (const node of pathWithoutRoot) {
                    headerRow.push(node.type);
                }
                headerAdded = true;
                rows.push(headerRow);
            }
            const row = [];
            for (const node of pathWithoutRoot) {
                row.push(node.name);
            }
            rows.push(row);
        }
        exportToCsv(`${props.project}-viz.csv`, rows);
    };
    const backDisabled = typeChain.length === 0;
    const backDisabledAttr = backDisabled ? ' pvtDisabledAttribute' : '';
    const isEmpty = typeChain.length === 0 || data.children.length === 0;
    const emptyElement = isEmpty ? (
        typeChain.length === 0 ? (
            <Empty
                description={
                    <span>Empty type chain. Click the Plus button to add data.</span>
                }></Empty>
        ) : (
            <Empty
                description={
                    <span style={{ color: 'red' }}>
                        No data matched the selected filters. Consider resetting your filters.
                    </span>
                }>
                <Button type="primary" onClick={() => setValuesFilter({})}>
                    Reset all Filters
                </Button>
            </Empty>
        )
    ) : (
        <></>
    );
    return (
        <>
            <div id="addRemoveRow">
                <Row gutter={16}>
                    <Col span={6}>
                        <Card title="Layers" bordered={false}>
                            <div className={'pvtAxisContainer pvtUnused pvtHorizList'}>
                                <Popover
                                    content={
                                        <List
                                            dataSource={Array.from(
                                                selectableEntityTypes
                                            ).sort((a, b) => a.localeCompare(b))}
                                            renderItem={item => (
                                                <p>
                                                    <a
                                                        onClick={() => {
                                                            onClick(item);
                                                            setAddTypeVisible(false);
                                                        }}>
                                                        {item}
                                                    </a>
                                                </p>
                                            )}
                                            itemLayout={'vertical'}
                                        />
                                    }
                                    title="Add Type"
                                    trigger="click"
                                    visible={addTypeVisible}
                                    onVisibleChange={visible => setAddTypeVisible(visible)}>
                                    <button
                                        style={{ margin: '0.5em 0.3em 0.5em 0.3em' }}
                                        disabled={selectableEntityTypes.size === 0}>
                                        <PlusOutlined /> Add Layer
                                    </button>
                                </Popover>
                                <button
                                    style={{ margin: '0.5em 0.2em 0.5em 0.2em' }}
                                    disabled={typeChain.length === 0}
                                    onClick={() => onClick('Back')}>
                                    <DeleteOutlined /> Remove Layer
                                </button>
                            </div>
                        </Card>
                    </Col>
                    <Col span={14}>
                        <Card title="Apply Filters" bordered={false}>
                            <div className={'pvtAxisContainer pvtUnused pvtHorizList'}>
                                {Object.keys(attrValues).map(entityType => (
                                    <DraggableAttribute
                                        name={entityType}
                                        key={entityType}
                                        attrValues={attrValues[entityType]}
                                        valueFilter={valuesFilter[entityType]}
                                        sorter={(a, b) => a.localeCompare(b)}
                                        menuLimit={500}
                                        setValuesInFilter={setValuesInFilter}
                                        addValuesToFilter={addValuesToFilter}
                                        moveFilterBoxToTop={() => {}}
                                        removeValuesFromFilter={removeValuesFromFilter}
                                        zIndex={1}
                                        onClick={() => onClick(entityType)}
                                        disabled={false}
                                    />
                                ))}
                            </div>
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card title="Actions" bordered={false}>
                            <Button
                                style={{
                                    backgroundColor: '#f2f5fa',
                                    border: '1px solid #a2b1c6',
                                    minHeight: '44px'
                                }}
                                onClick={downloadDataAsCSV}>
                                Download CSV
                            </Button>
                            {/* <Button */}
                            {/*    style={{ */}
                            {/*        backgroundColor: '#f2f5fa', */}
                            {/*        border: '1px solid #a2b1c6', */}
                            {/*        minHeight: '44px' */}
                            {/*    }} */}
                            {/*    onClick={downloadDataAsCSVWithScores} */}
                            {/*    loading={rankingInProgress}> */}
                            {/*    Download Ranked CSV */}
                            {/* </Button> */}
                        </Card>
                    </Col>
                </Row>
            </div>
            <div id="svgcontiner">
                <svg id="NetworkTreeSVG" ref={ref}></svg>
            </div>
            {emptyElement}
            <div className="spacer"></div>
            <Drawer
                title="References"
                placement="right"
                closable={false}
                onClose={() => setDrawerVisible(false)}
                visible={drawerVisible}>
                {currReference ? (
                    <List
                        itemLayout="vertical"
                        dataSource={currReference}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={
                                        <a
                                            target="_blank"
                                            href={`https://pubmed.ncbi.nlm.nih.gov/${item.id}`}>
                                            {item.title}
                                        </a>
                                    }
                                    description={renderExampleText(item.text)}
                                />
                            </List.Item>
                        )}
                    />
                ) : (
                    <div></div>
                )}
            </Drawer>
        </>
    );
}

export default NetworkTree;
