import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Button, Form, Input, message, Modal } from 'antd';
import { addRelationToProject, createOrModifyProject } from '../api';
import { PlusOutlined } from '@ant-design/icons';
import { noWhitespaceValidator } from './utils';

const AddRelationForm = ({ visible, onAdd, onCancel }) => {
    const [form] = Form.useForm();
    const layout = {
        labelCol: {
            span: 8
        },
        wrapperCol: {
            span: 16
        }
    };

    return (
        <Modal
            visible={visible}
            title="Add a relation to the project"
            okText="Add"
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
                form.validateFields()
                    .then(values => {
                        form.resetFields();
                        onAdd(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}>
            <Form {...layout} form={form} name="form_in_modal">
                <Form.Item
                    name="entity1Type"
                    label="Entity 1 Type"
                    rules={[
                        {
                            required: true,
                            message: 'Please input a value for the field'
                        },
                        {
                            validator: noWhitespaceValidator
                        }
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="relationLabel"
                    label="Relation Label"
                    rules={[
                        {
                            required: true,
                            message: 'Please input a value for the field'
                        },
                        {
                            validator: noWhitespaceValidator
                        }
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="entity2Type"
                    label="Entity 2 Type"
                    rules={[
                        {
                            required: true,
                            message: 'Please input a value for the field'
                        },
                        {
                            validator: noWhitespaceValidator
                        }
                    ]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const AddRelationToProjectDialog = (props: { projectName: string }) => {
    const [visible, setVisible] = useState(false);

    const onAdd = values => {
        console.log(`Received values of form for project ${props.projectName}:`, values);
        addRelationToProject(
            props.projectName,
            values.entity1Type,
            values.relationLabel,
            values.entity2Type
        )
            .then(res => {
                message.success('Relation added successfully');
                window.location.href = `/projects/${props.projectName}`;
            })
            .catch(e => message.error(`Failed adding relation to the project ${e}`));
        setVisible(false);
    };

    return (
        <>
            <Button
                onClick={() => {
                    setVisible(true);
                }}>
                <PlusOutlined /> Add Relation
            </Button>
            <AddRelationForm
                visible={visible}
                onAdd={onAdd}
                onCancel={() => {
                    setVisible(false);
                }}
            />
        </>
    );
};

export default AddRelationToProjectDialog;
