import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect, useParams, Link } from 'react-router-dom';
import { Select, ThemeProvider } from '@allenai/varnish';
import '@allenai/varnish/dist/varnish.css';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';

import ProjectsPage from './components/ProjectsPage';
import ProjectPage from './components/ProjectPage';
import LoginRedirect from './components/LoginRedirect';

ReactDOM.render(
    <BrowserRouter>
        <Auth0Provider
            domain="dev-kumd2t1h.us.auth0.com"
            clientId="G7FnKiG0TR2GoH1ri4RjMY8bhvBRTjUo"
            redirectUri={window.location.origin + '/loginCallback'}>
            <ThemeProvider>
                <Switch>
                    <Route exact path="/" component={() => <ProjectsPage />} />
                    <Route exact path="/projects/:projectName" component={() => <ProjectPage />} />
                    <Route exact path="/loginCallback">
                        <LoginRedirect />
                    </Route>
                </Switch>
            </ThemeProvider>
        </Auth0Provider>
    </BrowserRouter>,
    document.getElementById('root')
);
