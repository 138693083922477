import Dexie from 'dexie';
import { QueryTableData } from '../components/QueriesTable';
import md5 from 'md5';
import { Reference } from './core';

export interface QueryResults {
    id?: number;
    query: string;
    results: string;
}

export interface QueryInfo {
    id?: number;
    queryId: string;
    relation: string;
    queries: string;
    originalURL: string;
    originalQueryParams: string;
    caseStrategy: string;
}

export interface LocalSettings {
    username: string;
    maxResultsPerQuery: number;
    maxResultsPerEntityPair: number;
    filterOutKBEntityPairs: boolean;
}

export interface RemovedExample {
    exampleChecksum: string;
}

class HypEDatabase extends Dexie {
    // Declare implicit table properties.
    // (just to inform Typescript. Instanciated by Dexie in stores() method)
    queryResults: Dexie.Table<QueryResults, number>; // number = type of the primkey
    queriesTable: Dexie.Table<QueryInfo, number>;
    settings: Dexie.Table<LocalSettings, string>;
    removedExamples: Dexie.Table<RemovedExample, string>;

    constructor() {
        super('HypEDatabase');
        this.version(3).stores({
            queryResults: '++id, query, results',
            queriesTable:
                '++id, &queryId, relation, queries, originalURL, originalQueryParams, caseStrategy',
            settings:
                'username, maxResultsPerQuery, maxResultsPerEntityPair, filterOutKBEntityPairs',
            removedExamples: '&exampleChecksum'
        });

        // The following line is needed if your typescript
        // is compiled using babel instead of tsc:
        this.queryResults = this.table('queryResults');
        this.queriesTable = this.table('queriesTable');
        this.settings = this.table('settings');
        this.removedExamples = this.table('removedExamples');
    }

    clearQueryResults() {
        this.queryResults.clear();
    }

    clearQueries() {
        this.queriesTable.clear();
    }

    clearSettings() {
        this.settings.clear();
    }

    updateSettings(
        username: string,
        maxResultsPerQuery: number,
        maxResultsPerEntityPair: number,
        filterOutKBEntityPairs: boolean
    ) {
        this.settings
            .where('username')
            .equals(username)
            .delete();
        this.settings.put({
            username,
            maxResultsPerQuery,
            maxResultsPerEntityPair,
            filterOutKBEntityPairs
        });
    }

    addRemovedExample(exampleChecksum: string) {
        this.removedExamples.put({ exampleChecksum: exampleChecksum });
    }

    async getRemovedExamples(): Promise<Array<string>> {
        return (await this.removedExamples.toArray()).map(re => re.exampleChecksum);
    }
}

export const db = new HypEDatabase();
