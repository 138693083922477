import { Drawer, List, Tree } from 'antd';
import React, { useState } from 'react';
import { RelationInstance } from '../api';
import { Reference } from '../bl';
import './NetworkTree.css';
import { CarryOutOutlined } from '@ant-design/icons';
import { renderExampleText } from './QueryResultsTable';

interface TreeNode {
    name: string;
    type: string;
    children?: this[];
    parent?: this;
    references?: Reference[];
}

// const data = {
//     Biomaterial: {
//         Polysomething: {
//             'CONNECTS_TO Cancer': ['breast cancer', 'lung cancer'],
//             'CONNECTS_TO Target': ['HER2', 'ACE2']
//         },
//         Polysomethingelse: {
//             'CONNECTS_TO Cancer': ['breast cancer', 'lung cancer'],
//             'CONNECTS_TO Target': ['HER2', 'ACE2']
//         }
//     }
// };

const convertJsonDataToTreeData = data => {
    const treeData = [];
    for (const [etIdx, entityType] of Object.keys(data).entries()) {
        const etChildren = [];
        treeData.push({
            title: entityType,
            key: `${etIdx}`,
            icon: <CarryOutOutlined />,
            children: etChildren
        });
        for (const [e1Idx, entity1] of Object.keys(data[entityType])
            .sort((a, b) => a.localeCompare(b))
            .entries()) {
            const entity1Children = [];
            etChildren.push({
                title: entity1,
                key: `${etIdx}-${e1Idx}`,
                icon: <CarryOutOutlined />,
                children: entity1Children
            });
            for (const [relationIdx, relation] of Object.keys(data[entityType][entity1])
                .sort((a, b) => a.localeCompare(b))
                .entries()) {
                const relationChildren = [];
                entity1Children.push({
                    title: relation,
                    key: `${etIdx}-${e1Idx}-${relationIdx}`,
                    icon: <CarryOutOutlined />,
                    children: relationChildren
                });
                for (const [entity2Idx, entity2] of Object.keys(data[entityType][entity1][relation])
                    .sort((a, b) => a.localeCompare(b))
                    .entries()) {
                    relationChildren.push({
                        title: entity2,
                        key: `${etIdx}-${e1Idx}-${relationIdx}-${entity2Idx}`,
                        icon: <CarryOutOutlined />,
                        references: data[entityType][entity1][relation][entity2]
                    });
                }
            }
        }
    }
    return treeData;
};

function TreeView(props: {
    relationInstancesMap: {
        [k: string]: Array<RelationInstance>;
    };
    project: string;
    loading: boolean;
}) {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [currReference, setCurrReference] = useState<Reference[]>(null);
    const entityTypes = {};
    for (const relInsts of Object.values(props.relationInstancesMap)) {
        for (const relInst of relInsts) {
            if (relInst.relationLabel.startsWith('NEG_')) {
                continue;
            }
            if (!(relInst.entity1Type in entityTypes)) {
                entityTypes[relInst.entity1Type] = {};
            }
            if (!(relInst.entity2Type in entityTypes)) {
                entityTypes[relInst.entity2Type] = {};
            }
            if (!(relInst.entity1Name in entityTypes[relInst.entity1Type])) {
                entityTypes[relInst.entity1Type][relInst.entity1Name] = {};
            }
            if (!(relInst.entity2Name in entityTypes[relInst.entity2Type])) {
                entityTypes[relInst.entity2Type][relInst.entity2Name] = {};
            }
            const relAndEnt2Type = relInst.relationLabel + ' ' + relInst.entity2Type;
            if (!(relAndEnt2Type in entityTypes[relInst.entity1Type][relInst.entity1Name])) {
                entityTypes[relInst.entity1Type][relInst.entity1Name][relAndEnt2Type] = [];
            }
            if (
                entityTypes[relInst.entity1Type][relInst.entity1Name][relAndEnt2Type].includes(
                    relInst.entity2Name
                )
            ) {
                entityTypes[relInst.entity1Type][relInst.entity1Name][relAndEnt2Type][
                    relInst.entity2Name
                ].push({ text: relInst.refSentence, title: relInst.refTitle, id: relInst.refId });
            } else {
                entityTypes[relInst.entity1Type][relInst.entity1Name][relAndEnt2Type][
                    relInst.entity2Name
                ] = [
                    {
                        text: relInst.refSentence,
                        title: relInst.refTitle,
                        id: relInst.refId
                    }
                ];
            }
        }
    }
    const onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
        if ('references' in info.node) {
            setCurrReference(info.node.references);
            setDrawerVisible(true);
        }
    };
    return (
        <>
            <Tree
                showLine={true}
                showIcon={false}
                onSelect={onSelect}
                treeData={convertJsonDataToTreeData(entityTypes)}
            />
            <Drawer
                title="References"
                placement="right"
                closable={false}
                onClose={() => setDrawerVisible(false)}
                visible={drawerVisible}>
                {currReference ? (
                    <List
                        itemLayout="vertical"
                        dataSource={currReference}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={
                                        <a
                                            target="_blank"
                                            href={`https://pubmed.ncbi.nlm.nih.gov/${item.id}`}>
                                            {item.title}
                                        </a>
                                    }
                                    description={renderExampleText(item.text)}
                                />
                            </List.Item>
                        )}
                    />
                ) : (
                    <div></div>
                )}
            </Drawer>
        </>
    );
}

export default TreeView;
