import { SortOrder } from 'antd/es/table/interface';
import React, { MutableRefObject, useState } from 'react';
import { Button, Input, notification, Space, Table } from 'antd';
import { CaptureInfo, db, Example, Reference, runPatterns } from '../bl';
import {
    CheckOutlined,
    CopyOutlined,
    DeleteOutlined,
    CloseOutlined,
    SearchOutlined
} from '@ant-design/icons/lib';
import AddRelationDialog from './AddRelationDialog';
import { addRelation } from '../api';
import md5 from 'md5';
import { v4 as uuid } from 'uuid';
import './QueryResultsTable.css';
import { useParams } from 'react-router-dom';
import objectHash from 'object-hash';

interface Props {
    selectedHyponyms: MutableRefObject<Set<string>>;
    loading: boolean;
    resultsData: Array<QueryResultsTableData>;
    selectedRowsArray: Array<number>;
    setSelectedRowsArray: (rowKeys: Array<number>) => void;
    setRemovedExamples: (removedExamples: Set<string>) => void;
    entity1Type: string;
    entity2Type: string;
    relationLabel: string;
    username: string;
    setUpdateRelationInstancesMap: (updateRelationInstancesMap: string) => void;
}

export interface QueryResultsTableData {
    key: string;
    captures: Array<string>;
    total: number;
    reference: Reference;
}

export const enforceMaxResultsPerEntityPair = (
    queryResults: Array<QueryResultsTableData>,
    maxResultsPerEntityPair: number
): Array<QueryResultsTableData> => {
    const res: Array<QueryResultsTableData> = [];
    let prevQueryResult: QueryResultsTableData = null;
    let entityPairResultNum = 0;
    for (const queryResult of queryResults) {
        if (
            prevQueryResult === null ||
            queryResult.captures.map(c => c.toLowerCase()).join('***') !==
                prevQueryResult.captures.map(c => c.toLowerCase()).join('***')
        ) {
            entityPairResultNum = 0;
        }
        entityPairResultNum += 1;
        if (entityPairResultNum <= maxResultsPerEntityPair) {
            res.push(queryResult);
        }
        prevQueryResult = queryResult;
    }
    return res;
};

export async function runQueries(
    queries = [],
    maxResultsPerQuery
): Promise<Array<QueryResultsTableData>> {
    const res = await runPatterns(queries, maxResultsPerQuery);
    const newData = [];
    let i = 0;
    for (const captureInfo of res) {
        const sortedExamples = [...captureInfo.examples].sort(
            (a, b) => a.reference.text.length - b.reference.text.length
        );
        for (const example of sortedExamples) {
            newData.push({
                key: objectHash.MD5(example.reference),
                captures: captureInfo.capturedElements,
                total: captureInfo.totalHits,
                reference: example.reference
            });
            i += 1;
        }
    }
    return newData;
}

export function renderExampleText(text: string) {
    const modText = text.replace('<SEP>', '...');
    const e1OpenIndex = modText.indexOf('<e1>');
    const e1CloseIndex = modText.indexOf('</e1>');
    const e2OpenIndex = modText.indexOf('<e2>');
    const e2CloseIndex = modText.indexOf('</e2>');
    const firstOpen = e1OpenIndex < e2OpenIndex ? e1OpenIndex : e2OpenIndex;
    const firstClose = e1OpenIndex < e2OpenIndex ? e1CloseIndex : e2CloseIndex;
    const secondOpen = e1OpenIndex < e2OpenIndex ? e2OpenIndex : e1OpenIndex;
    const secondClose = e1OpenIndex < e2OpenIndex ? e2CloseIndex : e1CloseIndex;

    return (
        <span>
            {modText.substr(0, firstOpen)}
            <b>{modText.substr(firstOpen + 4, firstClose - firstOpen - 4)}</b>
            {modText.substr(firstClose + 5, secondOpen - firstClose - 5)}
            <b>{modText.substr(secondOpen + 4, secondClose - secondOpen - 4)}</b>
            {modText.substr(secondClose + 5)}
        </span>
    );
}

export function renderExample(example: Reference) {
    const titleElem =
        example.id === undefined ? (
            <Input className="disabledTitle" value={example.title} />
        ) : (
            <a href={'https://pubmed.ncbi.nlm.nih.gov/' + example.id} target="_blank">
                {example.title} {example.year ? `(${example.year})` : ''}
            </a>
        );
    return (
        <div>
            <div>{titleElem}</div>
            <div>{renderExampleText(example.text)}</div>
        </div>
    );
}

export const renderExamples = examples => (
    <div>
        {examples.map(text => {
            return [renderExample(text)];
        })}
    </div>
);

const QueryResultsTable = (props: Props) => {
    const projectName = useParams<{ projectName: string }>().projectName;
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>([]);
    const [selectedRows, setSelectedRows] = useState<Array<QueryResultsTableData>>([]);
    const [searchedText, setSearchedText] = useState<string>('');
    const [searchedColumn, setSearchedColumn] = useState<string>('');
    const hasSelected = selectedRowKeys.length > 0;

    const removeExample = async (example: Reference) => {
        const exampleChecksum = md5(example.id + example.text);
        db.addRemovedExample(exampleChecksum);
        db.getRemovedExamples().then(res =>
            props.setRemovedExamples(new Set([...res, exampleChecksum]))
        );
    };

    const markNegative = (entity1Name, relationLabel, entity2Name, ref: Reference) => {
        if (props.username === '') {
            notification.open({
                message: 'To annotate data you must set your username in the Settings dialog.',
                description: 'To annotate data you must set your username in the Settings dialog.'
            });
            return;
        }
        addRelation(
            `NEG_${props.entity1Type}`,
            entity1Name,
            entity1Name,
            `NEG_${relationLabel}`,
            `NEG_${props.entity2Type}`,
            entity2Name,
            entity2Name,
            ref.text,
            ref.title,
            ref.id,
            ref.year,
            props.username,
            projectName
        )
            .then(res => props.setUpdateRelationInstancesMap(uuid()))
            .catch(err =>
                notification.open({
                    message: 'Failed adding the relation: ' + err
                })
            );
    };

    const addRelationInstance = async (
        entity1Name: string,
        relationLabel: string,
        entity2Name: string,
        ref: Reference
    ) => {
        if (props.username === '') {
            notification.open({
                message: 'To annotate data you must set your username in the Settings dialog.',
                description: 'To annotate data you must set your username in the Settings dialog.'
            });
            return;
        }
        const results = await addRelation(
            props.entity1Type,
            entity1Name,
            entity1Name,
            relationLabel,
            props.entity2Type,
            entity2Name,
            entity2Name,
            ref.text,
            ref.title,
            ref.id,
            ref.year,
            props.username,
            projectName
        )
            .then(res => props.setUpdateRelationInstancesMap(uuid()))
            .catch(err =>
                notification.open({
                    message: 'Failed adding the relation: ' + err
                })
            );
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchedText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchedText('');
    };

    const getSentId = (reference: Reference) => {
        const rawSent = reference.text
            .replace('<e1>', '')
            .replace('</e1>', '')
            .replace('<e2>', '')
            .replace('</e2>', '');
        return objectHash([rawSent, reference.year, reference.id, reference.title]);
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    // ref={node => {
                    //     this.searchInput = node;
                    // }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchedText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}>
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            const recordValue =
                dataIndex === 'reference' ? getSentId(record[dataIndex]) : record[dataIndex];

            return recordValue
                ? recordValue
                      .toString()
                      .toLowerCase()
                      .startsWith(value.toLowerCase())
                : '';
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                // setTimeout(() => this.searchInput.select(), 100);
            }
        }
    });

    const columns = [
        {
            title: 'Result ID',
            dataIndex: 'key',
            key: 'resultId',
            render: key => <a>{key.substring(0, 5)}</a>,
            ...getColumnSearchProps('key')
        },
        {
            title: 'Sent ID',
            dataIndex: 'reference',
            key: 'sentId',
            render: reference => <a>{getSentId(reference).substring(0, 5)}</a>,
            ...getColumnSearchProps('reference')
        },
        {
            title: props.entity1Type,
            dataIndex: 'captures',
            key: 'entity1',
            render: captures => <a>{captures[0]}</a>
        },
        {
            title: props.entity2Type,
            dataIndex: 'captures',
            key: 'entity2',
            render: captures => <a>{captures[1]}</a>
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            defaultSortOrder: 'descend' as SortOrder,
            sorter: (a, b) => a.total - b.total
        },
        {
            title: `Example (${props.entity1Type} ${props.relationLabel} ${props.entity2Type})`,
            dataIndex: 'reference',
            key: 'reference',
            render: example => renderExample(example)
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '16%',
            render: (text, record, index) => (
                <span>
                    <Button
                        onClick={e =>
                            addRelationInstance(
                                record.captures[0],
                                props.relationLabel,
                                record.captures[1],
                                record.reference
                            )
                        }>
                        <CheckOutlined />
                    </Button>
                    <AddRelationDialog
                        reference={record.reference}
                        entity1Type={props.entity1Type}
                        entity1={record.captures[0]}
                        relationLabel={props.relationLabel}
                        entity2Type={props.entity2Type}
                        entity2={record.captures[1]}
                        setUpdateRelationInstancesTable={props.setUpdateRelationInstancesMap}
                        username={props.username}
                    />
                    <Button
                        onClick={e =>
                            markNegative(
                                record.captures[0],
                                props.relationLabel,
                                record.captures[1],
                                record.reference
                            )
                        }>
                        <CloseOutlined />
                    </Button>
                    <Button onClick={e => removeExample(record.reference)}>
                        <DeleteOutlined />
                    </Button>
                </span>
            )
        }
    ];

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        console.log('selectedRow changed: ', selectedRows);
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    const hideSelectionClicked = () => {
        const exampleChecksums = selectedRows.map(row =>
            md5(row.reference.id + row.reference.text)
        );
        for (const exampleChecksum of exampleChecksums) {
            db.addRemovedExample(exampleChecksum);
        }
        db.getRemovedExamples().then(res =>
            props.setRemovedExamples(new Set([...res, ...exampleChecksums]))
        );
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    const removeSelectionClicked = () => {
        for (const row of selectedRows) {
            markNegative(row.captures[0], props.relationLabel, row.captures[1], row.reference);
        }
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    const addSelectionClicked = () => {
        for (const row of selectedRows) {
            addRelationInstance(
                row.captures[0],
                props.relationLabel,
                row.captures[1],
                row.reference
            );
        }
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    return (
        <div>
            <Space>
                <Button onClick={addSelectionClicked} disabled={!hasSelected}>
                    <CheckOutlined />
                </Button>
                <Button onClick={removeSelectionClicked} disabled={!hasSelected}>
                    <CloseOutlined />
                </Button>
                <Button onClick={hideSelectionClicked} disabled={!hasSelected}>
                    <DeleteOutlined />
                </Button>
            </Space>
            <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={Array.from(props.resultsData.values())}
                loading={props.loading}
            />
        </div>
    );
};

export default QueryResultsTable;
