import React, { Dispatch, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Modal, Button, Row, Col, Input, notification, Checkbox } from 'antd';
import InputNumber from '@allenai/varnish/es/input-number';
import { SettingOutlined } from '@ant-design/icons/lib';
import { Select } from '@allenai/varnish';
import { db } from '../bl';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
    maxResultsPerQuery: number;
    setMaxResultsPerQuery: (maxResults: number) => void;
    maxResultsPerEntityPair: number;
    setMaxResultsPerEntityPair: (maxResults: number) => void;
    filterByEntityPair: boolean;
    setFilterByEntityPair: (filterByEntityPair: boolean) => void;
}

interface SelectOption {
    value: string;
    label: string;
}

const SettingsDialog = (props: Props) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [maxResultsPerQuery, setMaxResultsPerQuery] = useState<number>(props.maxResultsPerQuery);
    const [maxResultsPerEntityPair, setMaxResultsPerEntityPair] = useState<number>(
        props.maxResultsPerEntityPair
    );
    const [filterOutKBEntityPairs, setFilterOutKBEntityPairs] = useState<boolean>(
        props.filterByEntityPair
    );
    const { user } = useAuth0();
    const loadSettings = () => {
        console.log(' in load settings. user.email=' + user.email);
        db.settings
            .where('username')
            .equals(user.email)
            .toArray()
            .then(settingsRows => {
                if (settingsRows.length > 0) {
                    const settings = settingsRows[0];
                    console.log('settings');
                    console.log(settings);
                    setFilterOutKBEntityPairs(settings.filterOutKBEntityPairs);
                    props.setFilterByEntityPair(settings.filterOutKBEntityPairs);
                    setMaxResultsPerEntityPair(settings.maxResultsPerEntityPair);
                    props.setMaxResultsPerEntityPair(settings.maxResultsPerEntityPair);
                    setMaxResultsPerQuery(settings.maxResultsPerQuery);
                    props.setMaxResultsPerQuery(settings.maxResultsPerQuery);
                }
            });
    };

    useEffect(loadSettings, [user]);
    const deleteCacheBtnClicked = () => {
        db.clearQueryResults();
        notification.open({
            message: 'Results Cache Cleared!',
            description: 'Results Cache Cleared!'
        });
    };

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = e => {
        props.setMaxResultsPerQuery(maxResultsPerQuery);
        props.setMaxResultsPerEntityPair(maxResultsPerEntityPair);
        props.setFilterByEntityPair(filterOutKBEntityPairs);
        db.updateSettings(
            user.email,
            maxResultsPerQuery,
            maxResultsPerEntityPair,
            filterOutKBEntityPairs
        );
        setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const onMaxResultsChange = value => {
        setMaxResultsPerQuery(value);
    };

    const onMaxResultsPerEntityPairChagne = value => {
        setMaxResultsPerEntityPair(value);
    };

    const onFilterOutKBEntityPairs = e => {
        setFilterOutKBEntityPairs(e.target.checked);
    };

    return (
        <>
            <Button onClick={showModal}>
                <SettingOutlined /> Settings
            </Button>
            <Modal title="Settings" visible={visible} onOk={handleOk} onCancel={handleCancel}>
                <Row>
                    <Col span={12}>{'Max Results for Patterns Queries:'}</Col>
                    <Col span={12}>
                        <InputNumber
                            id="maxPatternQueryResults"
                            className="maxResults"
                            min={1}
                            max={10000}
                            value={maxResultsPerQuery}
                            onChange={onMaxResultsChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>{'Max Results per Entity Pair:'}</Col>
                    <Col span={12}>
                        <InputNumber
                            id="maxPatternQueryResults"
                            className="maxResults"
                            min={1}
                            max={10000}
                            value={maxResultsPerEntityPair}
                            onChange={onMaxResultsPerEntityPairChagne}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>Filter out KB entity Pairs:</Col>
                    <Col>
                        <Checkbox
                            checked={filterOutKBEntityPairs}
                            onChange={onFilterOutKBEntityPairs}></Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>Results Cache:</Col>
                    <Col>
                        <Button onClick={deleteCacheBtnClicked} className="control">
                            Delete
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default SettingsDialog;
